import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  var search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
// 防抖函数
export function _debounce(fn, wait) {
  var timer;
  return function () {
    var _arguments = arguments,
      _this = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(_this, _arguments);
    }, wait);
  };
}
// 节流函数
export function _throttle(fn, wait) {
  var startDate = Date.now();
  return function () {
    if (Date.now() - startDate > wait) {
      fn.apply(this, arguments);
      startDate = Date.now();
    }
  };
}

/**
 * data数据
 * maintitle 主类型，如：商品收益明细_
 * fileType 如：.xls
 * emimeType:如：application/vnd.ms-excel
 * customize:是否自定义时间
 */
export function download(data, mainTitle, fileType, emimeType, customize) {
  // 下载文件
  var blob = new Blob([data], {
    type: emimeType
  });
  var fileName = mainTitle + fileType;
  var a = document.createElement('a');
  document.body.appendChild(a);
  a.download = customize ? mainTitle + fileType : fileName;
  a.href = URL.createObjectURL(blob);
  a.click();
}

/**
 * 格式化
 */
export function formatValue(val) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'money';
  switch (type) {
    case 'money':
      return val ? "\xA5 ".concat(val) : '-';
  }
}
export function formatPrice(val) {
  if (val !== null && val !== undefined) {
    return "\xA5".concat(val);
  }
  return null;
}
export function isPhoneDev() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
}

// 判断浏览器函数
export function getToggleEquipment() {
  if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    return 'mobile-terminal'; // 移动端
  } else {
    return 'computer-terminal'; // PC端
  }
}

export function isInWechat() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}

// 取地址带的参数
export function getUrlParam(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  var r = window.location.hash.substr(1).split('?')[1];
  var result = '';
  if (r) result = r.match(reg);else return undefined;
  if (result != null) return decodeURIComponent(r[2]);
  return undefined;
}

//#409EFF
export function highlightWidthKeyword(name, keyword) {
  var color = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'red';
  if (name !== '' && keyword !== '') {
    name = name.replace(new RegExp(keyword, 'g'), "<i style=\"color:red; font-style: normal;\">".concat(keyword, "</i>"));
    if (keyword === null || keyword === undefined) {
      keyword = '';
    }
    keyword = keyword.toUpperCase();
    name = name.replace(new RegExp(keyword, 'g'), "<i style=\"color:red; font-style: normal;\">".concat(keyword, "</i>"));
  }
  return name;
}
export function ucfirst(str) {
  var firstLetter = str.substr(0, 1);
  return firstLetter.toUpperCase() + str.substr(1);
}
export function colF(fix) {
  var isEmptyList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (isEmptyList) {
    return false;
  }
  if (isPhoneDev()) {
    return false;
  }
  return fix;
}
export function notEmptyArray(arr) {
  return arr && Array.isArray(arr) && arr.length > 0;
}
export function notEmptyObject(obj) {
  if (!obj) {
    return false;
  }
  for (var key in obj) {
    return true;
  }
  return false;
}
export function cutString(str, len) {
  var temp,
    icount = 0,
    patrn = /[^\x00-\xff]/,
    strre = '';
  for (var i = 0; i < str.length; i++) {
    if (icount < len - 1) {
      temp = str.substr(i, 1);
      if (patrn.exec(temp) == null) {
        icount = icount + 1;
      } else {
        icount = icount + 2;
      }
      strre += temp;
    } else {
      break;
    }
  }
  if (str.length > strre.length) {
    strre = strre + '...';
  }
  return strre;
}
export function getSecondTimestamp() {
  return parseInt(new Date().getTime() / 1000);
}