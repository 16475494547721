var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !!_vm.urlList.length
      ? _c(
          "ul",
          { staticStyle: { margin: "0" } },
          _vm._l(_vm.urlList, function (i, index) {
            return _c("li", { key: index }, [
              _c(
                "a",
                {
                  staticClass: "font-link",
                  attrs: { href: i.url, target: "blank" },
                },
                [
                  _vm._v(
                    _vm._s(index + 1) + ". " + _vm._s(i.name || Date.now())
                  ),
                ]
              ),
            ])
          }),
          0
        )
      : _c("div", { staticClass: "padding-left-8 font-color-light-gray" }, [
          _vm._v("--"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }