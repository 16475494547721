import request from '@/utils/request';
export function login(data) {
  return request({
    url: 'user/login',
    method: 'post',
    data: data
  });
}
export function getInfo() {
  return request({
    url: 'user/view?expand=travelAgency,department',
    method: 'get'
  });
}
export function getAgencyInfo() {
  return request({
    url: 'account/agency',
    method: 'get'
  });
}
export function logout() {
  return request({
    url: 'user/logout',
    method: 'post'
  });
}
export function updatePwd(data) {
  return request({
    url: 'user/password',
    data: data,
    method: 'post'
  });
}
export function updateUser(data) {
  return request({
    url: 'user/update',
    data: data,
    method: 'put'
  });
}
export function remoteSearch(params) {
  return request({
    url: 'res/search',
    params: params,
    method: 'get'
  });
}
export function postMsgCode(data) {
  return request({
    url: 'sms-code/generate',
    data: data,
    method: 'post'
  });
}
export function codeLogin(data) {
  return request({
    url: '/user/mobile-login',
    data: data,
    method: 'post'
  });
}
export function forgetPassword(data) {
  return request({
    url: '/user/forget',
    data: data,
    method: 'post'
  });
}
export function verifyMobile(data) {
  return request({
    url: '/user/validate-mobile',
    data: data,
    method: 'post'
  });
}
export function modifyMobile(data) {
  return request({
    url: '/user/change-mobile',
    data: data,
    method: 'post'
  });
}
export function refreshToken() {
  return request({
    url: '/user/refresh-token',
    method: 'post'
  });
}
export function getWechatLoginQrcode() {
  return request({
    url: '/wx/generate-login-qrcode',
    method: 'post'
  });
}
export function getWechatLoginStatus(data) {
  return request({
    url: 'user/wx-login',
    method: 'post',
    data: data
  });
}

// 解绑微信
export function unbindWechat() {
  return request({
    url: 'user/unbind-wx',
    method: 'post'
  });
}

// 微信客户端中绑定：获取绑定微信跳转链接
export function getWechatBindUrl() {
  return request({
    url: 'wx/generate-bind-redirect-url',
    method: 'post'
  });
}

// 获得微信绑定状态
export function getWechatBindStatus(data) {
  return request({
    url: 'user/bind-wx',
    method: 'post',
    data: data
  });
}

//微信客户端中绑定：通过认证code绑定
export function bindWechatByCode(data) {
  return request({
    url: 'user/bind-wx-by-code',
    method: 'post',
    data: data
  });
}

// 微信客户端中登录：获取登录跳转链接
export function getWechatLoginUrl() {
  return request({
    url: 'wx/generate-login-redirect-url',
    method: 'post'
  });
}

// 微信客户端中登录
export function loginByWechat(data) {
  return request({
    url: 'user/wx-redirect-login',
    method: 'post',
    data: data
  });
}