import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _SOURCES, _TOUR_GUIDE_CERTIFICA;
import { ResEntityData } from "../Entity";
var TYPES = ResEntityData;
var SOURCE_CREATE_BY_SELF = 1;
var SOURCE_ADD_FROM_TRADE_CENTER = 2;
var SOURCES = (_SOURCES = {}, _defineProperty(_SOURCES, SOURCE_CREATE_BY_SELF, {
  id: SOURCE_CREATE_BY_SELF,
  label: '自建'
}), _defineProperty(_SOURCES, SOURCE_ADD_FROM_TRADE_CENTER, {
  id: SOURCE_ADD_FROM_TRADE_CENTER,
  label: '同业'
}), _SOURCES);
var TOUR_GUIDE_CERTIFICATE_LEVEL_JUNIOR = 1;
var TOUR_GUIDE_CERTIFICATE_LEVEL_INTERMEDIATE = 2;
var TOUR_GUIDE_CERTIFICATE_LEVEL_SENIOR = 3;
var TOUR_GUIDE_CERTIFICATE_LEVELS = (_TOUR_GUIDE_CERTIFICA = {}, _defineProperty(_TOUR_GUIDE_CERTIFICA, TOUR_GUIDE_CERTIFICATE_LEVEL_JUNIOR, {
  id: TOUR_GUIDE_CERTIFICATE_LEVEL_JUNIOR,
  label: '初级',
  color: 'black',
  icon: 'certificate-no-1'
}), _defineProperty(_TOUR_GUIDE_CERTIFICA, TOUR_GUIDE_CERTIFICATE_LEVEL_INTERMEDIATE, {
  id: TOUR_GUIDE_CERTIFICATE_LEVEL_INTERMEDIATE,
  label: '中级',
  color: 'blue',
  icon: 'certificate-no-2'
}), _defineProperty(_TOUR_GUIDE_CERTIFICA, TOUR_GUIDE_CERTIFICATE_LEVEL_SENIOR, {
  id: TOUR_GUIDE_CERTIFICATE_LEVEL_SENIOR,
  label: '高级',
  color: 'red',
  icon: 'certificate-no-3'
}), _TOUR_GUIDE_CERTIFICA);
var ResData = {
  TYPES: TYPES,
  SOURCE_CREATE_BY_SELF: SOURCE_CREATE_BY_SELF,
  SOURCE_ADD_FROM_TRADE_CENTER: SOURCE_ADD_FROM_TRADE_CENTER,
  SOURCES: SOURCES,
  TOUR_GUIDE_CERTIFICATE_LEVEL_JUNIOR: TOUR_GUIDE_CERTIFICATE_LEVEL_JUNIOR,
  TOUR_GUIDE_CERTIFICATE_LEVEL_INTERMEDIATE: TOUR_GUIDE_CERTIFICATE_LEVEL_INTERMEDIATE,
  TOUR_GUIDE_CERTIFICATE_LEVEL_SENIOR: TOUR_GUIDE_CERTIFICATE_LEVEL_SENIOR,
  TOUR_GUIDE_CERTIFICATE_LEVELS: TOUR_GUIDE_CERTIFICATE_LEVELS
};
export default ResData;