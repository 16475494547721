//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AddCarditem',
  props: {
    cardData: {
      type: Object,
      default: function _default() {
        return {};
      },
      required: true
    }
  },
  data: function data() {
    return {
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    };
  },
  methods: {
    // 添加账户
    addAccount: function addAccount() {
      this.cardData.bankAccounts.push({
        account_num: '',
        bank_name: '',
        open_bank_name: '',
        account_name: '',
        tax_num: ''
      });
    },
    // 删除账户
    handleDelete: function handleDelete(index, id, data, deleteData) {
      data.length ? data.splice(index, 1) : null;
      id ? deleteData.push(id) : null;
    }
  }
};