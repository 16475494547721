import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _PUBLISH_STATUSES, _CATEGORIES, _TRAVEL_DAYS_TYPES, _IMPORT_TYPES, _DISPLAY_MY_CLONE_OPT, _CLONE_PRODUCT_STATUS, _SCHEDULE_NUMS;
import Common from "../Common";
var STATUS_NOT_PUBLISHED = Common.NO;
var STATUS_PUBLISHED = Common.YES;
var PUBLISH_STATUSES = (_PUBLISH_STATUSES = {}, _defineProperty(_PUBLISH_STATUSES, STATUS_NOT_PUBLISHED, {
  id: STATUS_NOT_PUBLISHED,
  label: '未发布',
  icon: 'unpublished',
  color: 'gray'
}), _defineProperty(_PUBLISH_STATUSES, STATUS_PUBLISHED, {
  id: STATUS_PUBLISHED,
  label: '已发布',
  icon: 'published',
  color: 'green'
}), _PUBLISH_STATUSES);
var CATEGORY_ONE_DAY_TRAVEL = 1;
var CATEGORY_NEAR_BY_TRAVEL = 2;
var CATEGORY_LONG_TRAVEL = 3;
var CATEGORY_SELF_DRIVE_TRAVEL = 4;
var CATEGORIES = (_CATEGORIES = {}, _defineProperty(_CATEGORIES, CATEGORY_ONE_DAY_TRAVEL, {
  id: CATEGORY_ONE_DAY_TRAVEL,
  label: '一日游',
  color: 'green',
  icon: 'one-day-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_NEAR_BY_TRAVEL, {
  id: CATEGORY_NEAR_BY_TRAVEL,
  label: '周边游',
  color: 'blue',
  icon: 'near-by-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_LONG_TRAVEL, {
  id: CATEGORY_LONG_TRAVEL,
  label: '长线游',
  color: 'red',
  icon: 'long-travel'
}), _defineProperty(_CATEGORIES, CATEGORY_SELF_DRIVE_TRAVEL, {
  id: CATEGORY_SELF_DRIVE_TRAVEL,
  label: '自驾游',
  color: 'orange',
  icon: 'self-drive-travel'
}), _CATEGORIES);
var TRAVEL_DAYS_TYPE_1 = 1;
var TRAVEL_DAYS_TYPE_2 = 2;
var TRAVEL_DAYS_TYPE_3 = 3;
var TRAVEL_DAYS_TYPE_4 = 4;
var TRAVEL_DAYS_TYPE_5 = 5;
var TRAVEL_DAYS_TYPE_99 = 99;
var TRAVEL_DAYS_TYPES = (_TRAVEL_DAYS_TYPES = {}, _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_1, {
  id: TRAVEL_DAYS_TYPE_1,
  label: '1天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_2, {
  id: TRAVEL_DAYS_TYPE_2,
  label: '2天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_3, {
  id: TRAVEL_DAYS_TYPE_3,
  label: '3天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_4, {
  id: TRAVEL_DAYS_TYPE_4,
  label: '4天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_5, {
  id: TRAVEL_DAYS_TYPE_5,
  label: '5天'
}), _defineProperty(_TRAVEL_DAYS_TYPES, TRAVEL_DAYS_TYPE_99, {
  id: TRAVEL_DAYS_TYPE_99,
  label: '5+天'
}), _TRAVEL_DAYS_TYPES);
var IMPORT_TYPE_WX = 1;
var IMPORT_TYPE_XM = 2;
var IMPORT_TYPES = (_IMPORT_TYPES = {}, _defineProperty(_IMPORT_TYPES, IMPORT_TYPE_WX, {
  id: IMPORT_TYPE_WX,
  label: '微信公众号文章',
  icon: 'wx',
  color: 'green'
}), _defineProperty(_IMPORT_TYPES, IMPORT_TYPE_XM, {
  id: IMPORT_TYPE_XM,
  label: '秀米文章',
  icon: 'xiumi',
  color: 'orange'
}), _IMPORT_TYPES);
var ONLY_DISPALY_MY_CLONE = 1;
var ONLY_DISPLAY_MY_NOT_CLONE = 2;
var DISPLAY_MY_CLONE_OPTIONS = (_DISPLAY_MY_CLONE_OPT = {}, _defineProperty(_DISPLAY_MY_CLONE_OPT, ONLY_DISPALY_MY_CLONE, {
  id: ONLY_DISPALY_MY_CLONE,
  label: '只展示我已克隆产品'
}), _defineProperty(_DISPLAY_MY_CLONE_OPT, ONLY_DISPLAY_MY_NOT_CLONE, {
  id: ONLY_DISPLAY_MY_NOT_CLONE,
  label: '只展示我未克隆产品'
}), _DISPLAY_MY_CLONE_OPT);
var CLONE_PRODUCT_STATUS_NOT_PUBLISHED = Common.NO;
var CLONE_PRODUCT_STATUS_PUBLISHED = Common.YES;
var CLONE_PRODUCT_STATUS_DELETED = 2;
var CLONE_PRODUCT_STATUSES = (_CLONE_PRODUCT_STATUS = {}, _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_NOT_PUBLISHED, {
  id: CLONE_PRODUCT_STATUS_NOT_PUBLISHED,
  label: '未发布',
  icon: 'unpublished',
  color: 'gray'
}), _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_PUBLISHED, {
  id: CLONE_PRODUCT_STATUS_PUBLISHED,
  label: '已发布',
  icon: 'published',
  color: 'green'
}), _defineProperty(_CLONE_PRODUCT_STATUS, CLONE_PRODUCT_STATUS_DELETED, {
  id: CLONE_PRODUCT_STATUS_DELETED,
  label: '已删除',
  icon: 'el-icon-delete',
  color: 'red'
}), _CLONE_PRODUCT_STATUS);
var SCHEDULE_NUM_NONE = -1;
var SCHEDULE_NUM_FULL = 0;
var SCHEDULE_NUM_PLENTY = 9999;
var SCHEDULE_NUMS = (_SCHEDULE_NUMS = {}, _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_NONE, {
  id: SCHEDULE_NUM_NONE,
  label: '无',
  icon: '',
  color: 'gray'
}), _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_FULL, {
  id: SCHEDULE_NUM_FULL,
  label: '满',
  icon: '',
  color: 'red'
}), _defineProperty(_SCHEDULE_NUMS, SCHEDULE_NUM_PLENTY, {
  id: SCHEDULE_NUM_PLENTY,
  label: '充足',
  icon: '',
  color: 'green'
}), _SCHEDULE_NUMS);
var AgencyProductData = {
  STATUS_NOT_PUBLISHED: STATUS_NOT_PUBLISHED,
  STATUS_PUBLISHED: STATUS_PUBLISHED,
  PUBLISH_STATUSES: PUBLISH_STATUSES,
  CATEGORY_ONE_DAY_TRAVEL: CATEGORY_ONE_DAY_TRAVEL,
  CATEGORY_NEAR_BY_TRAVEL: CATEGORY_NEAR_BY_TRAVEL,
  CATEGORY_LONG_TRAVEL: CATEGORY_LONG_TRAVEL,
  CATEGORY_SELF_DRIVE_TRAVEL: CATEGORY_SELF_DRIVE_TRAVEL,
  CATEGORIES: CATEGORIES,
  TRAVEL_DAYS_TYPE_1: TRAVEL_DAYS_TYPE_1,
  TRAVEL_DAYS_TYPE_2: TRAVEL_DAYS_TYPE_2,
  TRAVEL_DAYS_TYPE_3: TRAVEL_DAYS_TYPE_3,
  TRAVEL_DAYS_TYPE_4: TRAVEL_DAYS_TYPE_4,
  TRAVEL_DAYS_TYPE_5: TRAVEL_DAYS_TYPE_5,
  TRAVEL_DAYS_TYPE_99: TRAVEL_DAYS_TYPE_99,
  TRAVEL_DAYS_TYPES: TRAVEL_DAYS_TYPES,
  IMPORT_TYPE_WX: IMPORT_TYPE_WX,
  IMPORT_TYPE_XM: IMPORT_TYPE_XM,
  IMPORT_TYPES: IMPORT_TYPES,
  ONLY_DISPALY_MY_CLONE: ONLY_DISPALY_MY_CLONE,
  ONLY_DISPLAY_MY_NOT_CLONE: ONLY_DISPLAY_MY_NOT_CLONE,
  DISPLAY_MY_CLONE_OPTIONS: DISPLAY_MY_CLONE_OPTIONS,
  CLONE_PRODUCT_STATUS_NOT_PUBLISHED: CLONE_PRODUCT_STATUS_NOT_PUBLISHED,
  CLONE_PRODUCT_STATUS_PUBLISHED: CLONE_PRODUCT_STATUS_PUBLISHED,
  CLONE_PRODUCT_STATUS_DELETED: CLONE_PRODUCT_STATUS_DELETED,
  CLONE_PRODUCT_STATUSES: CLONE_PRODUCT_STATUSES,
  SCHEDULE_NUM_NONE: SCHEDULE_NUM_NONE,
  SCHEDULE_NUM_FULL: SCHEDULE_NUM_FULL,
  SCHEDULE_NUM_PLENTY: SCHEDULE_NUM_PLENTY,
  SCHEDULE_NUMS: SCHEDULE_NUMS
};
export default AgencyProductData;