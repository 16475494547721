import displayIdStorageHandler from "./displayIdStorageHandler";
import agencyAccountHandler from "./agencyAccountHandler";
export default {
  mixins: [displayIdStorageHandler, agencyAccountHandler],
  methods: {
    redirect2ExternalUrl: function redirect2ExternalUrl(url) {
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (blank) {
        window.open(url, '_blank');
      } else {
        window.open(url);
      }
    },
    redirect2InternalUrl: function redirect2InternalUrl(url) {
      var blank = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (!blank) {
        this.$router.push(url);
      } else {
        var route = this.$router.resolve(url);
        window.open(route.href, '_blank');
      }
    },
    redirect2ModelListPage: function redirect2ModelListPage(model, displayId) {
      var blank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var url = model.listUri;
      this.setDisplayInfo(model.id, displayId);
      this.redirect2InternalUrl(url, blank);
    },
    redirect2ModelListPageByEntityId: function redirect2ModelListPageByEntityId(entityId, displayId) {
      var blank = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var model = this.$Models[entityId];
      this.redirect2ModelListPage(model, displayId, blank);
    },
    redirect2AgencyPage: function redirect2AgencyPage(agencyId) {
      var scrollToProductList = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var url = '/trade/agencyDetail?agencyId=' + agencyId;
      if (scrollToProductList) {
        url += '&autoScrollHeightFlag=1';
      }
      this.redirect2InternalUrl(url);
    },
    redirect2SelfAgencyPage: function redirect2SelfAgencyPage() {
      var scrollToProductList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2AgencyPage(this.$store.getters.agencyId, scrollToProductList);
    },
    redirect2BuyPage: function redirect2BuyPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/userCenter/createOrder?upSystemVersion=3', blank);
    },
    redirect2ResBatchImportPage: function redirect2ResBatchImportPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/batchImport', blank);
    },
    redirect2AgencyInfoPage: function redirect2AgencyInfoPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/employee/agency', blank);
    },
    redirect2MyProductListPage: function redirect2MyProductListPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/product/mine', blank);
    },
    redirect2MyProductSitePage: function redirect2MyProductSitePage() {
      this.redirect2ExternalUrl(this.myAgency.product_site_url);
    },
    redirect2SystemChangeRecordPage: function redirect2SystemChangeRecordPage() {
      var blank = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.redirect2InternalUrl('/userCenter/changeLog', blank);
    }
  }
};