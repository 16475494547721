import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _USER_ROLES, _USER_DUTIES;
var USER_ROLE_SUPER_ADMIN = 1;
var USER_ROLE_ADMIN = 2;
var USER_ROLE_ORDINARY_USER = 3;
var USER_ROLES = (_USER_ROLES = {}, _defineProperty(_USER_ROLES, USER_ROLE_SUPER_ADMIN, {
  id: USER_ROLE_SUPER_ADMIN,
  label: '超级管理员',
  color: 'red'
}), _defineProperty(_USER_ROLES, USER_ROLE_ADMIN, {
  id: USER_ROLE_ADMIN,
  label: '管理员',
  color: 'orange'
}), _defineProperty(_USER_ROLES, USER_ROLE_ORDINARY_USER, {
  id: USER_ROLE_ORDINARY_USER,
  label: '普通用户',
  color: 'black'
}), _USER_ROLES);
var USER_DUTY_SALES = 1;
var USER_DUTY_OPERATOR = 2;
var USER_DUTY_ACCOUNTING = 4;
var USER_DUTIES = (_USER_DUTIES = {}, _defineProperty(_USER_DUTIES, USER_DUTY_SALES, {
  id: USER_DUTY_SALES,
  label: '销售',
  color: 'blue'
}), _defineProperty(_USER_DUTIES, USER_DUTY_OPERATOR, {
  id: USER_DUTY_OPERATOR,
  label: '计调',
  color: 'blue'
}), _defineProperty(_USER_DUTIES, USER_DUTY_ACCOUNTING, {
  id: USER_DUTY_ACCOUNTING,
  label: '财务',
  color: 'blue'
}), _USER_DUTIES);
var AccountData = {
  USER_ROLE_SUPER_ADMIN: USER_ROLE_SUPER_ADMIN,
  USER_ROLE_ADMIN: USER_ROLE_ADMIN,
  USER_ROLE_ORDINARY_USER: USER_ROLE_ORDINARY_USER,
  USER_ROLES: USER_ROLES,
  USER_DUTY_SALES: USER_DUTY_SALES,
  USER_DUTY_OPERATOR: USER_DUTY_OPERATOR,
  USER_DUTY_ACCOUNTING: USER_DUTY_ACCOUNTING,
  USER_DUTIES: USER_DUTIES
};
export default AccountData;