import "core-js/modules/es6.function.name";
import _typeof from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import redirect2UrlHandler from '@/views/mixins/redirect2UrlHandler';
import { remoteSearch } from '@/api/user';
import { highlightWidthKeyword } from '@/utils/index';
export default {
  name: 'HeaderSearch',
  mixins: [redirect2UrlHandler],
  data: function data() {
    return {
      keyword: '',
      searchItems: [],
      show: false,
      selectShow: true,
      queryName: '',
      highlightWidthKeyword: highlightWidthKeyword
    };
  },
  methods: {
    click: function click() {
      this.show = !this.show;
      if (this.searchItems) this.searchItems = [];
      if (this.show) {
        this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
      }
    },
    change: function change(id) {
      var _this = this;
      //window.location.href = val
      var item = null;
      for (var i = 0; i < this.searchItems.length; i++) {
        item = this.searchItems[i];
        if (item.id === Number(id)) {
          break;
        }
      }
      if (item) {
        this.keyword = '';
        this.show = false;
        this.selectShow = false;
        setTimeout(function () {
          _this.selectShow = true;
          _this.redirect2ModelListPageByEntityId(item.res_type, item.res_id);
        }, 0);
      }
    },
    querySearch: function querySearch(query) {
      var _this2 = this;
      if (query) {
        query = _typeof(query) === 'object' ? '' : query;
        this.queryName = query;
        remoteSearch({
          name: query
        }).then(function (res) {
          var data = res.data;
          _this2.searchItems = data;
        });
      }
    },
    itemLabel: function itemLabel(item) {
      return "\u3010".concat(item.res_type_name, "\u3011").concat(item.name);
    },
    highlightItemLabel: function highlightItemLabel(item) {
      return "\u3010".concat(item.res_type_name, "\u3011 ").concat(highlightWidthKeyword(item.name, this.queryName));
    }
  }
};