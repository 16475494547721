var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Sticky",
    { attrs: { "z-index": 10, "sticky-top": 50 } },
    [
      _c(
        "el-row",
        {
          staticClass: "padding-left-right-24 sub-navbar",
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "mobile-visual",
                  rawName: "v-mobile-visual",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { md: 12, xs: 24 },
            },
            [_vm._t("tab")],
            2
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { staticClass: "text-align-right", attrs: { md: 10, xs: 24 } },
            [_vm._t("action")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }