//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: this.$store.state.user.userInfo.agency_name || '旅行社',
      logo: require('@/assets/images/logo-light.png')
    };
  },
  mounted: function mounted() {}
};