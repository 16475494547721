var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-collapse-transition", [
    _c(
      "div",
      {
        staticClass: "filter-container product-search-box transition-box",
        staticStyle: {
          "background-color": "#fcfcfc",
          "margin-bottom": "5px",
          "border-radius": "10px",
          "border-top": "1px solid #eee",
        },
      },
      [
        _c(
          "el-row",
          [
            _c("el-col", { attrs: { span: 2, xs: 24 } }, [
              _c("span", { staticClass: "fontSize-12 font-color-gray" }, [
                _c("b", [_vm._v("设置要显示的列")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-col",
              { attrs: { span: 22, xs: 24 } },
              [
                _vm._l(_vm.displayColumns, function (config, name) {
                  return [
                    _c(
                      "el-tag",
                      {
                        key: name,
                        class: _vm.getColumnDisplayTagClass(name),
                        attrs: {
                          size: "small",
                          type: _vm.getColumnDisplayTagType(name),
                          effect: _vm.getColumnDisplayTagEffect(name),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.configColumnDisplay(name)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(config.text) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }