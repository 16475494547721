var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "upgrade",
          attrs: { href: "#/userCenter/createOrder?upSystemVersion=3" },
        },
        [
          _vm.isFreeVersion
            ? _c("span", { staticClass: "label" }, [_vm._v("升级到付费版")])
            : _vm._e(),
          _vm._v(" "),
          _vm.isBasicVersion
            ? _c("span", { staticClass: "label" }, [_vm._v("升级到高级版")])
            : _vm._e(),
          _vm._v(" "),
          _vm.isPrimaryVersion
            ? _c("span", { staticClass: "label" }, [_vm._v("延长使用时间")])
            : _vm._e(),
          _vm._v(" "),
          _c("sup", { staticClass: "discount-label" }, [_vm._v("特惠")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "span",
            {
              staticClass: "header-back margin-right-16 font-link fontSize-14",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "back", "class-name": "back" },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "font-link fontSize-14" }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("search", {
            staticClass: "header-search right-menu-item",
            attrs: { id: "header-search" },
          }),
          _vm._v(" "),
          _c("addProduct", { staticClass: "header-info" }),
          _vm._v(" "),
          _c("Info", { staticClass: "header-info" }),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container" },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.name
                  ? _c(
                      "div",
                      {
                        staticClass: "div-avatar user-avatar",
                        style: { background: _vm.bgColors[_vm.userId % 9] },
                      },
                      [_vm._v(_vm._s(_vm.name[0]))]
                    )
                  : _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("@/assets/images/user.png") },
                    }),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/account/index" } },
                    [
                      _c("el-dropdown-item", [
                        _c("i", { staticClass: "el-icon-user" }),
                        _vm._v(
                          "\n                        我的账号\n                    "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _c("i", { staticClass: "el-icon-switch-button" }),
                        _vm._v(
                          "\n                        退出登录\n                    "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }