import "core-js/modules/es6.function.name";
import { login as _login, logout as _logout, getInfo as _getInfo, codeLogin, refreshToken as _refreshToken } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    roles: '',
    userId: '',
    managerId: '',
    userInfo: {},
    myAgency: null,
    msgNum: 0,
    isInitPass: 1,
    // 是
    identifier: '',
    // 店铺标识
    hasProductSite: 0,
    // 是否有店铺
    totalProductCount: 0,
    // 总产品数量
    publishedProductCount: 0,
    // 发布产品数量
    isWxBind: '',
    // 微信绑定
    systemVersion: 1,
    // 1:免费版 2:基础班 3:高级版
    systemVersionName: '',
    isCertificated: '',
    certificateAuditStatus: '',
    totalUserNum: 0,
    usedUserNum: 0,
    totalDiskSpace: 0,
    usedDiskSpace: 0,
    usedDiskPercent: 0,
    singleFileSize: 0,
    leftDateDesc: '',
    agencyType: null,
    // 1: 组团社 2:地接社
    agencyName: null,
    // 1: 组团社 2:地接社
    agencyId: null,
    // 旅行社Id
    invitationLink: '',
    hasSalesDuty: 0,
    hasOperatorDuty: 0,
    hasAccountingDuty: 0,
    productSite: {
      url: '',
      qrcodeUrl: ''
    },
    today: null
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_AGENCY_TYPE: function SET_AGENCY_TYPE(state, params) {
    state.agencyType = params;
  },
  SET_AGENCY_ID: function SET_AGENCY_ID(state, params) {
    state.agencyId = params;
  },
  SET_AGENCY_NAME: function SET_AGENCY_NAME(state, params) {
    state.agencyName = params;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USERID: function SET_USERID(state, userId) {
    state.userId = userId;
  },
  SET_MANAGERID: function SET_MANAGERID(state, managerId) {
    state.managerId = managerId;
  },
  SET_USER: function SET_USER(state, user) {
    state.userInfo = user;
  },
  SET_TODAY: function SET_TODAY(state, today) {
    state.today = today;
  },
  SET_MY_AGENCY: function SET_MY_AGENCY(state, travelAgency) {
    state.myAgency = travelAgency;
  },
  SET_MSGNUM: function SET_MSGNUM(state, num) {
    state.msgNum = num;
  },
  SET_INIT_PASS: function SET_INIT_PASS(state, params) {
    state.isInitPass = params;
  },
  SET_IDENTIFIER: function SET_IDENTIFIER(state, params) {
    state.identifier = params;
  },
  SET_PRODUCT_SITE: function SET_PRODUCT_SITE(state, params) {
    state.hasProductSite = params;
  },
  SET_TOTAL_PRODUCT_COUNT: function SET_TOTAL_PRODUCT_COUNT(state, params) {
    state.totalProductCount = params;
  },
  SET_PUBLISHED_PRODUCT_COUNT: function SET_PUBLISHED_PRODUCT_COUNT(state, params) {
    state.publishedProductCount = params;
  },
  SET_WX_BIND: function SET_WX_BIND(state, params) {
    state.isWxBind = params;
  },
  SET_SYS_VERSION: function SET_SYS_VERSION(state, params) {
    state.systemVersion = params;
  },
  SET_SYS_VERSION_NAME: function SET_SYS_VERSION_NAME(state, params) {
    state.systemVersionName = params;
  },
  SET_IS_CERTIFICATED: function SET_IS_CERTIFICATED(state, params) {
    state.isCertificated = params;
  },
  SET_IS_CERTIFICATEAUDITSTATUS: function SET_IS_CERTIFICATEAUDITSTATUS(state, params) {
    state.certificateAuditStatus = params;
  },
  SET_TOTAL_USER_NUM: function SET_TOTAL_USER_NUM(state, params) {
    state.totalUserNum = params;
  },
  SET_USED_USER_NUM: function SET_USED_USER_NUM(state, params) {
    state.usedUserNum = params;
  },
  SET_TOTAL_DISK_SPACE: function SET_TOTAL_DISK_SPACE(state, params) {
    state.totalDiskSpace = params;
  },
  SET_USED_DISK_SPACE: function SET_USED_DISK_SPACE(state, params) {
    state.usedDiskSpace = params;
  },
  SET_USED_DISK_PERCENT: function SET_USED_DISK_PERCENT(state, params) {
    state.usedDiskPercent = params;
  },
  SET_SINGLE_FILE_SIZE: function SET_SINGLE_FILE_SIZE(state, params) {
    state.singleFileSize = params;
  },
  SET_LEFT_DATE_DESC: function SET_LEFT_DATE_DESC(state, params) {
    state.leftDateDesc = params;
  },
  SET_PRODUCTSITE: function SET_PRODUCTSITE(state, params) {
    state.productSite = params;
  },
  SET_INVITATION_LINK: function SET_INVITATION_LINK(state, params) {
    state.invitationLink = params;
  },
  SET_HASSALESDUTY: function SET_HASSALESDUTY(state, params) {
    state.hasSalesDuty = params;
  },
  SET_HASOPERATORDUTY: function SET_HASOPERATORDUTY(state, params) {
    state.hasOperatorDuty = params;
  },
  SET_HASACCOUNTINGDUTY: function SET_HASACCOUNTINGDUTY(state, params) {
    state.hasAccountingDuty = params;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch;
    return new Promise(function (resolve, reject) {
      _login(userInfo).then(function (res) {
        commit('SET_TOKEN', res.data.token);
        setToken(res.data.token);
        resolve(res.data);
      }, function (error) {
        return reject(error);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  mobileLogin: function mobileLogin(_ref2, userInfo) {
    var commit = _ref2.commit,
      dispatch = _ref2.dispatch;
    return new Promise(function (resolve, reject) {
      codeLogin(userInfo).then(function (res) {
        commit('SET_TOKEN', res.data.token);
        setToken(res.data.token);
        resolve(res.data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _getInfo(state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('校验失败，请重新登录');
        }
        var role = data.role,
          role_name = data.role_name,
          name = data.name,
          tid = data.tid,
          id = data.id,
          mobile = data.mobile,
          gender = data.gender,
          email = data.email,
          department_id = data.department_id,
          department_name = data.department_name,
          agency_name = data.agency_name,
          duty_names = data.duty_names,
          is_init_pass = data.is_init_pass,
          wx_openid = data.wx_openid,
          travelAgency = data.travelAgency,
          agency_id = data.agency_id,
          invitation_link = data.invitation_link,
          hasSalesDuty = data.hasSalesDuty,
          hasOperatorDuty = data.hasOperatorDuty,
          hasAccountingDuty = data.hasAccountingDuty,
          today = data.today;
        var identifier = travelAgency.identifier,
          product_site_status = travelAgency.product_site_status,
          total_product_count = travelAgency.total_product_count,
          published_product_count = travelAgency.published_product_count,
          system_version = travelAgency.system_version,
          system_version_name = travelAgency.system_version_name,
          is_certificated = travelAgency.is_certificated,
          certificate_audit_status = travelAgency.certificate_audit_status,
          total_user_num = travelAgency.total_user_num,
          used_user_num = travelAgency.used_user_num,
          total_disk_space = travelAgency.total_disk_space,
          used_disk_space = travelAgency.used_disk_space,
          used_disk_percent = travelAgency.used_disk_percent,
          single_file_size = travelAgency.single_file_size,
          left_date_desc = travelAgency.left_date_desc,
          product_site_url = travelAgency.product_site_url,
          product_site_qrcode_url = travelAgency.product_site_qrcode_url,
          agency_type = travelAgency.agency_type;
        commit('SET_ROLES', [role]);
        commit('SET_NAME', name);
        commit('SET_USERID', tid);
        commit('SET_TODAY', today);
        commit('SET_MANAGERID', id);
        commit('SET_USER', {
          mobile: mobile,
          gender: gender,
          email: email,
          name: name,
          department_id: department_id,
          role_name: role_name,
          department_name: department_name,
          duty_names: duty_names,
          agency_name: agency_name
        });
        commit('SET_MY_AGENCY', travelAgency);
        commit('SET_INIT_PASS', is_init_pass);
        commit('SET_IDENTIFIER', identifier);
        commit('SET_PRODUCT_SITE', product_site_status);
        commit('SET_TOTAL_PRODUCT_COUNT', total_product_count);
        commit('SET_PUBLISHED_PRODUCT_COUNT', published_product_count);
        commit('SET_WX_BIND', wx_openid);
        commit('SET_SYS_VERSION', system_version);
        commit('SET_SYS_VERSION_NAME', system_version_name);
        commit('SET_IS_CERTIFICATED', is_certificated);
        commit('SET_IS_CERTIFICATEAUDITSTATUS', certificate_audit_status);
        commit('SET_TOTAL_USER_NUM', total_user_num);
        commit('SET_USED_USER_NUM', used_user_num);
        commit('SET_TOTAL_DISK_SPACE', total_disk_space);
        commit('SET_USED_DISK_SPACE', used_disk_space);
        commit('SET_USED_DISK_PERCENT', used_disk_percent);
        commit('SET_SINGLE_FILE_SIZE', single_file_size);
        commit('SET_LEFT_DATE_DESC', left_date_desc);
        commit('SET_PRODUCTSITE', {
          url: product_site_url,
          qrcodeUrl: product_site_qrcode_url
        });
        // 1：组团社 2：地接社
        commit('SET_AGENCY_TYPE', agency_type);
        commit('SET_AGENCY_NAME', agency_name);
        commit('SET_AGENCY_ID', agency_id);
        commit('SET_INVITATION_LINK', invitation_link);
        commit('SET_HASSALESDUTY', hasSalesDuty);
        commit('SET_HASOPERATORDUTY', hasOperatorDuty);
        commit('SET_HASACCOUNTINGDUTY', hasAccountingDuty);
        localStorage.role = role;
        localStorage.name = name;
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },
  refreshToken: function refreshToken(_ref6) {
    var commit = _ref6.commit,
      state = _ref6.state;
    return new Promise(function (resolve, reject) {
      _refreshToken().then(function (res) {
        console.log('res: ', res);
        resolve(res.data.token);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};