//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PhonePreview',
  data: function data() {
    return {
      loading: true,
      show: true
    };
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    previewUrl: function previewUrl() {
      if (this.src.indexOf('?') > 0) {
        return this.src + '&t=1';
      } else {
        return this.src + '?t=1';
      }
    }
  },
  mounted: function mounted() {
    var self = this;
    this.$nextTick(function () {
      var iframe = document.querySelector('#ifra');
      if (iframe.attachEvent) {
        iframe.attachEvent('onload', function () {
          self.loading = false;
        });
      } else {
        iframe.onload = function () {
          self.loading = false;
        };
      }
    });
  },
  watch: {
    src: function src(newval) {
      var _this = this;
      if (newval) {
        this.show = false;
        this.loading = true;
        this.$nextTick(function () {
          _this.show = true;
        });
        setTimeout(function () {
          return _this.loading = false;
        }, 1000);
      }
    }
  }
};