var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "blank", style: _vm.heightStyle },
    [
      _vm.type === "data"
        ? [
            _c(
              "p",
              [
                _c("svg-icon", {
                  style: { height: _vm.size + "px", width: _vm.size + "px" },
                  attrs: { "icon-class": "no" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.label
              ? _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ])
              : _vm._e(),
          ]
        : _vm.type === "auth"
        ? [
            _c(
              "p",
              [
                _c("svg-icon", {
                  style: { height: _vm.size + "px", width: _vm.size + "px" },
                  attrs: { "icon-class": "auth" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.label
              ? _c("span", { staticClass: "label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }