//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    urlList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  }
};