//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      require: true,
      type: String
    },
    title: {
      type: String
    }
  },
  data: function data() {
    return {
      types: {
        warn: {
          icon: 'el-icon-warning-outline',
          bgColor: '#fffbe6',
          borderColor: '#FFE48F',
          iconColor: '#FAAD14'
        },
        success: {
          icon: 'el-icon-circle-check',
          bgColor: '#f6ffed',
          borderColor: '#b7eb8f',
          iconColor: '#53C31B'
        },
        info: {
          icon: 'el-icon-info',
          bgColor: '#eee',
          borderColor: '#AAE2FD',
          iconColor: '#999'
        }
      }
    };
  }
};