import request from '@/utils/request';
export function postProduct(data) {
  return request({
    url: 'product',
    method: 'post',
    data: data
  });
}
export function getProduct(params) {
  return request({
    url: 'product?expand=simpleDepartureDistrictName,simpleDestinationDistrictName,bannerImages,coverImage,tripScheduleFile, nearest3NumbersDisplay,latest7Browsers',
    method: 'get',
    params: params
  });
}

// 产品详情
export function getProductDetail(id) {
  return request({
    url: "product/".concat(id, "?expand=content,numbers,bannerImages,coverImage,tripScheduleFile"),
    method: 'get'
  });
}
export function getProductDetailForEditing(id) {
  return request({
    url: "product/".concat(id, "?expand=content,bannerImages,coverImage,tripScheduleFile"),
    method: 'get'
  });
}
export function delProduct(id) {
  return request({
    url: 'product/' + id,
    method: 'delete'
  });
}
export function deletePrimaryProductSchedule(params) {
  return request({
    url: 'product-schedule/delete/' + params.id,
    method: 'delete',
    params: {
      date: params.date
    }
  });
}

// 批量删除线路产品
export function delProductList(ids) {
  return request({
    url: 'product/batch-delete',
    method: 'delete',
    data: ids
  });
}
export function updateProduct(id, data) {
  return request({
    url: 'product/' + id,
    method: 'put',
    data: data
  });
}

// 产品主页
export function postProductMain(data) {
  return request({
    url: '/product/update-site-profile',
    method: 'post',
    data: data
  });
}

// 主页详情
export function getProductMainDetail() {
  return request({
    url: "/product/site-profile?expand=logo,bannerImage,userQrcodeImage",
    method: 'get'
  });
}

/** 产品预览
 * @param data 创建的数据
 */

export function getProductPreview(data) {
  return request({
    url: "/product/preview",
    method: 'post',
    data: data
  });
}
// 导入微信公众号或秀米文章
export function getWxArtical(url) {
  return request({
    url: '/product/import',
    method: 'post',
    data: {
      url: url
    }
  });
}

// 更新产品发布状态
export function updatePublishStatus(id, status) {
  return request({
    url: "/product/publish/".concat(id),
    method: 'post',
    data: {
      status: status
    }
  });
}

// 更新产品热门标签状态
export function updateHotStatus(data) {
  return request({
    url: "/product/set-hot/".concat(data.id),
    method: 'post',
    data: {
      hot: data.is_hot
    }
  });
}

// 获取所有班期的产品
export function getAllScheduleProduct(params) {
  return request({
    url: "/product-schedule?expand=simplestDepartureDistrictName",
    method: 'get',
    params: params
  });
}

// 获取所有班期的产品
export function getProductScheduleList(productId) {
  return request({
    url: "/product-schedule/list/".concat(productId),
    method: 'get'
  });
}

// 获取产品的所有班期
export function getAllSchedule(productId) {
  return request({
    url: "/product-schedule/all/".concat(productId),
    method: 'get'
  });
}

// 获取产品的某天班期
export function getDateSchedule(id, params) {
  return request({
    url: "/product-schedule/date/".concat(id),
    method: 'get',
    params: params
  });
}

// 获取产品的某月班期
export function getMonthSchedule(params) {
  return request({
    url: "/product-schedule/month/".concat(params.id),
    method: 'get'
  });
}

// 设置产品的某天班期
export function setDateSchedule(id, data) {
  return request({
    url: "/product-schedule/set/date/".concat(id),
    method: 'post',
    data: data
  });
}

// 设置产品的某月班期
export function setMonthSchedule(id, data) {
  return request({
    url: "/product-schedule/set/month/".concat(id),
    method: 'post',
    data: data
  });
}

// 获取已发布的产品
export function getPublishedProductList() {
  return request({
    url: "/product/published-product-id-titles",
    method: 'get'
  });
}

// 获取生成产品标签
export function getProductTags(data) {
  return request({
    url: '/product/tag/product',
    method: 'post',
    data: data
  });
}

// 获取生成店铺热门标签
export function getProductTagsSite(data) {
  return request({
    url: '/product/tag/site',
    method: 'post',
    data: data
  });
}
// 获取员工列表
export function getUserIdNames(params) {
  return request({
    url: "/account/employee/user-id-names",
    method: 'get',
    params: params
  });
}

// 获取部门列表
export function getDepartmentIdNames(params) {
  return request({
    url: "/account/employee/department-id-names",
    method: 'get',
    params: params
  });
}

// 获取所有线路产品
export function getProductAll(params) {
  return request({
    url: "/product/all",
    method: 'get',
    params: params
  });
}