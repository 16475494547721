import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sectionId: {
      type: Number,
      required: true
    },
    name: {
      type: String
    },
    label: {
      type: String
    },
    icon: {
      type: String
    },
    editLinkClickCallback: {
      type: Function
    },
    enableEditLink: {
      type: [Boolean, Number],
      default: true
    },
    displayAsPlusLink: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sectionConfig: function sectionConfig() {
      return this.$MetaData.SECTIONS[this.sectionId];
    },
    computedName: function computedName() {
      if (this.name) {
        return this.name;
      }
      return this.sectionConfig.name;
    },
    computedLabel: function computedLabel() {
      if (this.label) {
        return this.label;
      }
      return this.sectionConfig.label;
    },
    computedIcon: function computedIcon() {
      if (this.icon) {
        return this.icon;
      }
      return this.sectionConfig.icon;
    },
    editLinkIcon: function editLinkIcon() {
      return this.displayAsPlusLink ? 'el-icon-plus' : 'el-icon-edit';
    },
    editLinkLabel: function editLinkLabel() {
      return this.displayAsPlusLink ? '添加' : '编辑';
    }
  },
  methods: {
    handleEdit: function handleEdit() {
      this.editLinkClickCallback(this.computedName);
    }
  }
};