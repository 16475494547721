import waves from "./waves";
var install = function install(Vue) {
  Vue.directive('waves', waves);
};
if (window.Vue) {
  window.waves = waves;
  Vue.use(install); // eslint-disable-line
}

waves.install = install;
export default waves;