//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ConditionPanelItem',
  inject: ['slotItems', 'labelWidth'],
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      default: ''
    },
    span: {
      default: 6
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      prefixCls: 'conditionPanelItem'
    };
  },
  computed: {
    computedClass: function computedClass() {
      var classes = [this.prefixCls + '-label'];
      if (this.selected) {
        classes.push(this.prefixCls + '-label--selected');
      }
      return classes;
    },
    endSpan: function endSpan() {
      var numberLen = parseInt(24 / this.span);
      return (this.slotItems.length % numberLen ? numberLen + 1 - this.slotItems.length % numberLen : 1) * this.span;
    }
  }
};