//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var LIST_FILTER = 1;
var COLUMN_DISPLAY = 2;
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    isInFiltering: {
      type: Boolean
    },
    enableMultipleDeleteButton: {
      type: Boolean,
      default: true
    },
    multipleSelection: {
      type: Array
    },
    multiDeleteHandler: {
      type: Function
    },
    enableCreateButton: {
      type: Boolean,
      default: true
    },
    createButtonLabel: {
      type: String
    },
    createButtonIcon: {
      type: String
    },
    createButtonClickedCallback: {
      type: Function
    },
    enableColumnDisplay: {
      type: Boolean,
      default: true
    },
    enableListFilter: {
      type: Boolean,
      default: true
    },
    showListFilter: {
      type: Boolean
    },
    showColumnDisplay: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      LIST_FILTER: LIST_FILTER,
      COLUMN_DISPLAY: COLUMN_DISPLAY
    };
  },
  computed: {
    isSelected: function isSelected() {
      return this.multipleSelection.length > 0;
    },
    modelCreateUri: function modelCreateUri() {
      if (this.model.createUri !== null && this.model.createUri.length > 0) {
        return this.model.createUri;
      }
      return false;
    },
    computedCreateButtonIcon: function computedCreateButtonIcon() {
      if (this.createButtonIcon) {
        return this.createButtonIcon;
      }
      return 'el-icon-circle-plus';
    },
    computedCreateButtonLabel: function computedCreateButtonLabel() {
      if (this.createButtonLabel) {
        return this.createButtonLabel;
      }
      return '添加' + this.model.label;
    },
    popconConfirmTitle: function popconConfirmTitle() {
      return '确定删除选中' + this.model.label + '么？';
    },
    listFilterButtonType: function listFilterButtonType() {
      return this.activeListFilter ? 'primary' : '';
    },
    columnDisplayButtonType: function columnDisplayButtonType() {
      return this.activeColumnDisplay ? 'success' : '';
    },
    activeListFilter: {
      get: function get() {
        return this.showListFilter;
      },
      set: function set(active) {
        this.$emit('update:showListFilter', active);
      }
    },
    activeColumnDisplay: {
      get: function get() {
        return this.showColumnDisplay;
      },
      set: function set(active) {
        this.$emit('update:showColumnDisplay', active);
      }
    },
    searchBtnTooltip: function searchBtnTooltip() {
      if (this.showListFilter) {
        return '收起搜索';
      } else {
        return '展开搜索';
      }
    },
    columnDisplayBtnTooltip: function columnDisplayBtnTooltip() {
      if (this.showColumnDisplay) {
        return '收起列显示设置';
      } else {
        return '展开列显示设置';
      }
    }
  },
  methods: {
    switchActive: function switchActive(type) {
      if (type === LIST_FILTER) {
        if (this.activeColumnDisplay) {
          this.activeColumnDisplay = false;
        }
        this.activeListFilter = !this.activeListFilter;
      } else if (type === COLUMN_DISPLAY) {
        if (this.activeListFilter) {
          this.activeListFilter = false;
        }
        this.activeColumnDisplay = !this.activeColumnDisplay;
      }
    },
    handleCreateButtonClick: function handleCreateButtonClick() {
      if (this.createButtonClickedCallback) {
        return this.createButtonClickedCallback();
      }
    }
  }
};