import "core-js/modules/es6.regexp.constructor";
var _this = this;
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}
var validateMobileRequire = function validateMobileRequire(rule, value, callback) {
  var reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
  if (!value) {
    callback(new Error('请输入手机号'));
  } else if (!reg.test(value)) {
    callback(new Error('手机号码格式不正确'));
  } else {
    callback();
  }
};
var validateMobile = function validateMobile(rule, value, callback) {
  var reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
  if (value && !reg.test(value)) {
    callback(new Error('手机号码格式不正确'));
  } else {
    callback();
  }
};
var validatePwdRequire = function validatePwdRequire(rule, value, callback) {
  // const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,30}$/;
  var reg = /^.{6,32}$/g;
  if (!value) {
    callback(new Error('请输入密码'));
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的密码格式： 6~32位字母、数字和常用特殊符号组成'));
  } else {
    callback();
  }
};
var validatePhone = function validatePhone(rule, value, callback) {
  var reg = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/;
  if (value && !reg.test(value)) {
    callback(new Error('请输入正确的座机格式'));
  } else {
    callback();
  }
};
var validateID = function validateID(rule, value, callback) {
  var reg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  if (value && !reg.test(value)) {
    callback(new Error('请输入正确的身份证格式'));
  } else {
    callback();
  }
};
var validatePwd = function validatePwd(rule, value, callback) {
  var reg = /^.{6,32}$/;
  if (!value) {
    callback();
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的密码格式：6~32位字母、数字和常用特殊符号组成'));
  } else {
    callback();
  }
};

/**
 * 校验 整数
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
var checkInt = function checkInt(rule, value, callback) {
  var reg = new RegExp('^(0|[0-9]*[1-9][0-9]*)$');
  if (value) {
    if (!reg.test(value)) {
      var msg = '请输入不小于0的整数';
      callback(new Error(msg));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
export var checkPositiveInteger = function checkPositiveInteger(rule, value, callback) {
  var reg = new RegExp('^[1-9][0-9]*$'); //匹配正整数
  if (value) {
    if (!reg.test(value)) {
      callback(new Error(rule.message || '请输入大于0的整数')); // 不包含0
    } else {
      callback();
    }
  } else {
    callback();
  }
};

/**
 * 校验 小数
 * @param {*} digital
 * @param {*} isPos
 * @param {*} tarObj
 */
var validateDecimal = function validateDecimal(digital, isPos, tarObj) {
  return function (rule, value, callback) {
    if (tarObj) {
      value = tarObj.value;
    }
    var resStr = "^".concat(isPos ? '' : '-?', "[1-9]\\d*\\.?\\d").concat('{0,' + digital + '}', "$|^").concat(isPos ? '' : '-?', "0\\.?\\d").concat('{0,' + digital + '}', "$");
    var reg = new RegExp(resStr);
    if (value) {
      if (!reg.test(value)) {
        var msg = "\u8BF7\u8F93\u5165\u6B63\u786E\u683C\u5F0F\u7684\u6570\u5B57\uFF0C\u6700\u591A".concat(digital, "\u4F4D\u5C0F\u6570");
        callback(new Error(msg));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
};

// 自定义validator ：required直接可以显示 红星
export default {
  validateDefault: [{
    message: '必填项',
    trigger: 'blur'
  }],
  validateName: [{
    required: true,
    message: '请填写名称',
    trigger: 'blur'
  }],
  validateCode: [{
    required: true,
    message: '请填写验证码',
    trigger: 'blur'
  }],
  validateMobile: [{
    validator: validateMobile,
    trigger: 'blur'
  }],
  validateMobileRequire: [{
    validator: validateMobileRequire,
    trigger: 'blur',
    required: true
  }],
  validatePhone: [{
    validator: validatePhone,
    trigger: 'blur'
  }],
  validatePwdRequire: [{
    required: true,
    validator: validatePwdRequire,
    trigger: 'blur'
  }],
  // 函数式检验
  validateConfirmPassword: function validateConfirmPassword(context) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'postForm';
    return [{
      require: true,
      validator: function validator(rule, value, callback) {
        console.log('this', _this);
        if (!value) {
          callback(new Error('请输入确认密码'));
        } else if (value !== context[key].password) {
          callback(new Error('确认密码不正确'));
        } else {
          callback();
        }
      },
      trigger: 'blur'
    }];
  },
  validateID: [{
    validator: validateID,
    trigger: 'blur'
  }],
  validateEmail: {
    type: 'email',
    message: '请输入正确的邮箱地址格式',
    trigger: ['blur', 'change']
  },
  validatePwd: [{
    validator: validatePwd,
    trigger: 'blur'
  }],
  validateKeep2Decimal: [{
    validator: validateDecimal(2, true),
    trigger: 'blur'
  }],
  validateKeep2DecimalNegative: [{
    validator: validateDecimal(2, false),
    trigger: 'blur'
  }],
  checkInt: [{
    validator: checkInt,
    trigger: 'blur'
  }],
  validateKeepPositiveInteger: [{
    validator: checkPositiveInteger,
    trigger: 'blur'
  }]
};