import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _TYPES;
var TYPE_IMAGE = 1;
var TYPE_TXT = 2;
var TYPE_WORD = 3;
var TYPE_EXCEL = 4;
var TYPE_PPT = 5;
var TYPE_PDF = 6;
var TYPE_ZIP = 7;
var TYPE_EXE = 8;
var TYPE_OTHER = 99;
var TYPES = (_TYPES = {}, _defineProperty(_TYPES, TYPE_IMAGE, {
  id: TYPE_IMAGE,
  label: '图片文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_TXT, {
  id: TYPE_TXT,
  label: '文本文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_WORD, {
  id: TYPE_WORD,
  label: 'Word文档',
  icon: ''
}), _defineProperty(_TYPES, TYPE_EXCEL, {
  id: TYPE_EXCEL,
  label: 'Excel表格',
  icon: ''
}), _defineProperty(_TYPES, TYPE_PPT, {
  id: TYPE_PPT,
  label: 'PPT文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_PDF, {
  id: TYPE_PDF,
  label: 'PDF文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_ZIP, {
  id: TYPE_ZIP,
  label: '压缩文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_EXE, {
  id: TYPE_EXE,
  label: '可执行文件',
  icon: ''
}), _defineProperty(_TYPES, TYPE_OTHER, {
  id: TYPE_OTHER,
  label: '其他文件',
  icon: ''
}), _TYPES);
var FileData = {
  TYPE_IMAGE: TYPE_IMAGE,
  TYPE_TXT: TYPE_TXT,
  TYPE_WORD: TYPE_WORD,
  TYPE_EXCEL: TYPE_EXCEL,
  TYPE_PPT: TYPE_PPT,
  TYPE_PDF: TYPE_PDF,
  TYPE_ZIP: TYPE_ZIP,
  TYPE_EXE: TYPE_EXE,
  TYPE_OTHER: TYPE_OTHER,
  TYPES: TYPES
};
export default FileData;