import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken } from '@/utils/auth';
import { uploadFile } from '@/api/file';
export default {
  name: 'UploadImage',
  props: {
    value: {
      type: [Object, Array],
      default: function _default() {
        return {
          url: '',
          id: '',
          name: ''
        };
      }
    },
    // 裁剪模式
    isCropper: {
      type: Boolean,
      default: false
    },
    // 裁剪容器宽度
    cropperWrapperBoxWidth: {
      type: Number,
      default: 800
    },
    // 容器宽
    width: {
      type: String,
      default: '240px'
    },
    // 容器高
    height: {
      type: String,
      default: '160px'
    },
    // 裁剪比例
    cropperRatio: {
      type: Array,
      default: function _default() {
        return [2, 1];
      }
    },
    // 整体删除 用于批量图片裁剪，单个不需要
    delCallback: {
      type: Function
    }
  },
  data: function data() {
    return {
      cropperOption: {
        img: '',
        previewStyle: {}
      },
      previews: {},
      accept: ' image/*',
      action: process.env.VUE_APP_BASE_API + 'file/upload',
      dialogImageUrl: '',
      dialogVisible: false,
      cropperVisible: false,
      isUploading: false,
      percentage: 0,
      Authorization: "Bearer ".concat(getToken()),
      viewsImageWidth: '50%'
    };
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value ? this.value.url : void 0;
    },
    equipment: function equipment() {
      return this.$store.state.app.equipment;
    },
    cropperWrapperWidth: function cropperWrapperWidth() {
      if (this.$isPhoneDev) {
        return '100%';
      }
      return this.cropperWrapperBoxWidth - 340 + 'px';
    },
    cropperWrapperHeight: function cropperWrapperHeight() {
      return parseInt(this.cropperWrapperBoxWidth - 340) * 0.7 + 'px';
    },
    dialogWidth: function dialogWidth() {
      if (this.$isPhoneDev) {
        return '100%';
      }
      return this.cropperWrapperBoxWidth + 'px';
    },
    cropperWrapperMobileStyle: function cropperWrapperMobileStyle() {
      if (this.$isPhoneDev) {
        return 'display:block;';
      }
      return '';
    },
    imgPreviewWrapperMobileStyle: function imgPreviewWrapperMobileStyle() {
      if (this.$isPhoneDev) {
        return 'margin-top:20px;margin-left:0px;';
      }
      return '';
    },
    enlargeVal: function enlargeVal() {
      return this.$isPhoneDev ? 2 : 1;
    }
  },
  created: function created() {
    if (this.equipment === 'mobile-terminal') {
      this.viewsImageWidth = '100%';
    }
  },
  methods: {
    onchange: function onchange(e) {
      var url = URL.createObjectURL(this.$refs.input.files[0]);
      this.cropperOption.img = url;
    },
    // 上传裁剪图片
    handleUploadCropper: function handleUploadCropper() {
      this.$refs.input.click();
    },
    // 生成封面
    handleCropper: function handleCropper(res) {
      var _this = this;
      this.$refs.cropper.getCropBlob(function (blob) {
        var formData = new FormData();
        formData.append('file', blob, "\u5C01\u9762\u56FE\u7247-".concat(Date.now()));
        _this.cropperVisible = false;
        _this.handleBeforeUpload(blob.size);
        _this.handleProgress({
          percent: 30
        });
        uploadFile(formData).then(function (res) {
          _this.handleProgress({
            percent: 100
          });
          _this.handleImageSuccess(res);
        }).catch(function (err) {
          // failure("出现未知问题，刷新页面，或者联系程序员");
          console.log(err);
        });
      });
    },
    // 裁剪时预览
    realTimePreview: function realTimePreview(data) {
      if (data) {
        var previews = data;
        this.cropperOption.previewStyle = {
          width: previews.w + 'px',
          height: previews.h + 'px',
          overflow: 'hidden',
          margin: 'auto',
          zoom: 125 / previews.h
        };
        this.previews = data;
      }
    },
    handleProgress: function handleProgress(event) {
      console.log('event: ', event);
      this.percentage = event.percent;
    },
    emitValue: function emitValue(res) {
      this.$emit('update:value', res);
      this.$emit('update:id', res.id);
    },
    handleBeforeUpload: function handleBeforeUpload(file) {
      if (file && file.size > 2 * 1024 * 1000) {
        this.$message.warning('图片要小于 2MB');
        return false;
      }
      this.isUploading = true;
    },
    handleImageSuccess: function handleImageSuccess(res) {
      if (res.code !== 200) {
        this.$message.error({
          message: res.message
        });
      } else {
        this.isUploading = false;
        this.emitValue(res.data);
      }
    },
    handlePictureCardPreview: function handlePictureCardPreview() {
      this.dialogImageUrl = this.imageUrl;
      this.dialogVisible = true;
    },
    handlePictureCardDelete: function handlePictureCardDelete() {
      this.$emit('update:value', {
        url: '',
        id: '',
        name: ''
      });
      this.$emit('update:id', null);
    }
  }
};