//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterComponentHandler from '@/mixins/filterComponentHandler.js';
export default {
  mixins: [filterComponentHandler],
  props: {
    value: {
      type: String
    }
  },
  computed: {
    computedPlaceholder: function computedPlaceholder() {
      var pc = '';
      if (this.placeholder) {
        pc = this.placeholder;
      } else {
        pc = '请输入' + this.label;
      }
      //return pc + '，按回车搜索'

      return pc;
    },
    emptyValue: function emptyValue() {
      return this.value === undefined || this.value === null || this.value === '' || typeof this.value === 'string' && this.value.length === 0;
    }
  },
  methods: {
    onNameChange: function onNameChange() {
      return this.callback(this.attr, this.value);
    },
    onClear: function onClear() {
      return this.callback(this.attr, '');
    }
  }
};