import store from '@/store';
export default {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var equipment = store.state.app.equipment;
    // 在手机端显示
    if (value && equipment !== 'mobile-terminal') {
      el.parentNode && el.parentNode.removeChild(el);
    }
    // 在手机端隐藏
    if (!value && equipment === 'mobile-terminal') {
      el.parentNode && el.parentNode.removeChild(el);
    }
  }
};