import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // 标签最大字数
    maxLength: {
      type: Number,
      default: 5
    },
    // 最大数量
    maxTotal: {
      type: Number,
      default: 5
    },
    // 标签值
    value: {
      require: true,
      type: Array,
      default: function _default() {
        return [];
      }
    },
    effect: {
      type: 'dark' | 'light' | 'plain',
      default: 'light'
    },
    type: {
      type: 'danger' | 'warning' | 'info' | 'success',
      default: ''
    },
    // modal 模式下
    // TODO：属性校验 modal 模式下 必须加入此属性
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      inputVisible: false,
      inputValue: '',
      valueMapDtatus: [] // 映射 value 的状态数组
    };
  },

  computed: {
    isShow: function isShow() {
      return this.inputValue && this.value.length < this.maxTotal;
    }
  },
  watch: {
    visible: function visible(newval) {
      if (newval) {
        this.valueMapDtatus = this.value.map(function (i) {
          return false;
        });
      } else {
        this.valueMapDtatus = [];
      }
    }
  },
  mounted: function mounted() {
    // init valueMapDtatus
    var unwatch = this.$watch('value', function (newValue, oldValue) {
      this.valueMapDtatus = this.value.map(function (i) {
        return false;
      });
      unwatch();
    });
  },
  methods: {
    handleClose: function handleClose(tag) {
      var index = this.value.indexOf(tag);
      this.value.splice(index, 1);
      this.valueMapDtatus.splice(index, 1);
    },
    addTags: function addTags(tag) {
      this.value.push('标签');
      this.valueMapDtatus.push(false);
      // this.$refs['saveTagInput'][`${this.value.length - 1}`].$refs.input.focus()
    },
    showInputCurrent: function showInputCurrent(index) {
      var _this = this;
      this.$set(this.valueMapDtatus, index, true);
      this.$nextTick(function (_) {
        _this.$refs["saveTagInput".concat(index)][0].$refs.input.focus();
      });
    },
    handleInputConfirm: function handleInputConfirm(index) {
      if (!this.value[index]) {
        this.value.splice(index, 1);
        this.valueMapDtatus.splice(index, 1);
      } else {
        this.$set(this.valueMapDtatus, index, false);
      }
    }
  }
};