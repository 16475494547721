var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Alert",
      style: {
        borderColor: _vm.types[_vm.type].borderColor,
        background: _vm.types[_vm.type].bgColor,
      },
    },
    [
      _c("i", {
        class: _vm.types[_vm.type].icon,
        style: {
          color: _vm.types[_vm.type].iconColor,
          fontSize: "18px",
          marginTop: _vm.title ? "0" : "4px",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "alert-title-wrapper" }, [
        _vm.title
          ? _c("p", { staticClass: "alert-title" }, [_vm._v(_vm._s(_vm.title))])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "alert-info" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }