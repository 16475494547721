var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDisplay
    ? _c("el-table-column", {
        attrs: {
          label: _vm.columnLabel,
          prop: _vm.columnProp,
          effect: "plain",
          width: _vm.columnWidth,
          "min-width": _vm.columnMinWidth,
          "max-width": _vm.xw,
          sortable: _vm.sortable,
          "show-overflow-tooltip": _vm.o,
          fixed: _vm.columnFixed,
          "class-name": _vm.className,
          align: _vm.a,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function (ref) {
                var row = ref.row
                return [
                  _vm._t(
                    "default",
                    [
                      _vm.isNotEmptyContent(row)
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getContent(row)),
                            },
                          })
                        : _vm.displayEmptyContent
                        ? _c("EmptyContentSpan")
                        : _vm._e(),
                    ],
                    { row: row }
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }