import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _TYPES, _BUSINESS_TYPES, _SITE_STATUSES, _CERTIFICATE_STATUSES;
import Common from "../Common";
var TYPE_GROUP = 1;
var TYPE_LOCAL_GUIDE = 2;
var TYPES = (_TYPES = {}, _defineProperty(_TYPES, TYPE_GROUP, {
  id: TYPE_GROUP,
  label: '组团社',
  icon: 'agency-group',
  color: 'green'
}), _defineProperty(_TYPES, TYPE_LOCAL_GUIDE, {
  id: TYPE_LOCAL_GUIDE,
  label: '地接社',
  icon: 'agency-local-guide',
  color: 'orange'
}), _TYPES);
var BUSINESS_TYPE_DOMESTIC = 1;
var BUSINESS_TYPE_DOMESTIC_AND_FOREIGN = 2;
var BUSINESS_TYPES = (_BUSINESS_TYPES = {}, _defineProperty(_BUSINESS_TYPES, BUSINESS_TYPE_DOMESTIC, {
  id: BUSINESS_TYPE_DOMESTIC,
  label: '国内游',
  color: 'black'
}), _defineProperty(_BUSINESS_TYPES, BUSINESS_TYPE_DOMESTIC_AND_FOREIGN, {
  id: BUSINESS_TYPE_DOMESTIC_AND_FOREIGN,
  label: '国内游+境外游',
  color: 'black'
}), _BUSINESS_TYPES);
var SITE_STATUS_NOT_OPENED = -1;
var SITE_STATUS_NOT_PUBLISHED = Common.NO;
var SITE_STATUS_PUBLISHED = Common.YES;
var SITE_STATUSES = (_SITE_STATUSES = {}, _defineProperty(_SITE_STATUSES, SITE_STATUS_NOT_OPENED, {
  id: SITE_STATUS_NOT_OPENED,
  label: '未开通',
  color: 'red',
  icon: 'el-icon-remove'
}), _defineProperty(_SITE_STATUSES, SITE_STATUS_NOT_PUBLISHED, {
  id: SITE_STATUS_NOT_PUBLISHED,
  label: '未发布',
  color: 'gray',
  icon: 'unpublished'
}), _defineProperty(_SITE_STATUSES, SITE_STATUS_PUBLISHED, {
  id: SITE_STATUS_PUBLISHED,
  label: '已发布',
  color: 'green',
  icon: 'published'
}), _SITE_STATUSES);
var CERTIFICATE_STATUS_UNCERTIFICATED = 0;
var CERTIFICATE_STATUS_CERTIFICATED = 1;
var CERTIFICATE_STATUS_CERTIFICATING = 2;
var CERTIFICATE_STATUSES = (_CERTIFICATE_STATUSES = {}, _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_UNCERTIFICATED, {
  id: CERTIFICATE_STATUS_UNCERTIFICATED,
  label: '未认证',
  color: 'red'
}), _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_CERTIFICATING, {
  id: CERTIFICATE_STATUS_CERTIFICATING,
  label: '等待审核',
  color: 'blue'
}), _defineProperty(_CERTIFICATE_STATUSES, CERTIFICATE_STATUS_CERTIFICATED, {
  id: CERTIFICATE_STATUS_CERTIFICATED,
  label: '已认证',
  color: 'green'
}), _CERTIFICATE_STATUSES);
var TravelAgencyData = {
  TYPE_GROUP: TYPE_GROUP,
  TYPE_LOCAL_GUIDE: TYPE_LOCAL_GUIDE,
  TYPES: TYPES,
  BUSINESS_TYPE_DOMESTIC: BUSINESS_TYPE_DOMESTIC,
  BUSINESS_TYPE_DOMESTIC_AND_FOREIGN: BUSINESS_TYPE_DOMESTIC_AND_FOREIGN,
  BUSINESS_TYPES: BUSINESS_TYPES,
  SITE_STATUS_NOT_OPENED: SITE_STATUS_NOT_OPENED,
  SITE_STATUS_NOT_PUBLISHED: SITE_STATUS_NOT_PUBLISHED,
  SITE_STATUS_PUBLISHED: SITE_STATUS_PUBLISHED,
  SITE_STATUSES: SITE_STATUSES,
  CERTIFICATE_STATUS_UNCERTIFICATED: CERTIFICATE_STATUS_UNCERTIFICATED,
  CERTIFICATE_STATUS_CERTIFICATED: CERTIFICATE_STATUS_CERTIFICATED,
  CERTIFICATE_STATUS_CERTIFICATING: CERTIFICATE_STATUS_CERTIFICATING,
  CERTIFICATE_STATUSES: CERTIFICATE_STATUSES
};
export default TravelAgencyData;