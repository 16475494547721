// 我的店铺 - 线路产品列表页面
var PAGE_CODE_MY_SHOP_AGENCY_PRODUCT_LIST = 1;

// 我的店铺 - 销售订单
var PAGE_CODE_SALES_ORDER_LIST = 2;

// 采购订单
var PAGE_CODE_BUYER_ORDER_LIST = 2;

// 资源中心 - 旅行社列表页面
var PAGE_CODE_RESOURCE_CENTER_TRAVEL_AGENCY_LIST = 41;

// 资源中心 - 酒店列表页面
var PAGE_CODE_RESOURCE_CENTER_HOTEL_LIST = 42;

// 资源中心 - 景区列表页面
var PAGE_CODE_RESOURCE_CENTER_LANDSCAPE_LIST = 43;

// 资源中心 - 导游列表页面
var PAGE_CODE_RESOURCE_CENTER_TOUR_GUIDE_LIST = 44;

// 资源中心 - 车队列表页面
var PAGE_CODE_RESOURCE_CENTER_BUS_COMPANY_LIST = 45;

// 资源中心 - 餐厅列表页面
var PAGE_CODE_RESOURCE_CENTER_RESTAURANT_LIST = 46;

// 资源中心 - 商店列表页面
var PAGE_CODE_RESOURCE_CENTER_SHOP_LIST = 47;

// 资源中心 - 游客列表页面
var PAGE_CODE_RESOURCE_CENTER_TRAVELLER_LIST = 48;

// 文件
var PAGE_CODE_FILE_LIST = 60;
var PageParam = {
  PAGE_CODE_MY_SHOP_AGENCY_PRODUCT_LIST: PAGE_CODE_MY_SHOP_AGENCY_PRODUCT_LIST,
  PAGE_CODE_SALES_ORDER_LIST: PAGE_CODE_SALES_ORDER_LIST,
  PAGE_CODE_BUYER_ORDER_LIST: PAGE_CODE_BUYER_ORDER_LIST,
  PAGE_CODE_RESOURCE_CENTER_TRAVEL_AGENCY_LIST: PAGE_CODE_RESOURCE_CENTER_TRAVEL_AGENCY_LIST,
  PAGE_CODE_RESOURCE_CENTER_HOTEL_LIST: PAGE_CODE_RESOURCE_CENTER_HOTEL_LIST,
  PAGE_CODE_RESOURCE_CENTER_LANDSCAPE_LIST: PAGE_CODE_RESOURCE_CENTER_LANDSCAPE_LIST,
  PAGE_CODE_RESOURCE_CENTER_TOUR_GUIDE_LIST: PAGE_CODE_RESOURCE_CENTER_TOUR_GUIDE_LIST,
  PAGE_CODE_RESOURCE_CENTER_BUS_COMPANY_LIST: PAGE_CODE_RESOURCE_CENTER_BUS_COMPANY_LIST,
  PAGE_CODE_RESOURCE_CENTER_RESTAURANT_LIST: PAGE_CODE_RESOURCE_CENTER_RESTAURANT_LIST,
  PAGE_CODE_RESOURCE_CENTER_SHOP_LIST: PAGE_CODE_RESOURCE_CENTER_SHOP_LIST,
  PAGE_CODE_RESOURCE_CENTER_TRAVELLER_LIST: PAGE_CODE_RESOURCE_CENTER_TRAVELLER_LIST,
  PAGE_CODE_FILE_LIST: PAGE_CODE_FILE_LIST
};
export default PageParam;