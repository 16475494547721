import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _CATEGORIES, _STATUSES;
var CATEGORY_MY_AGENCY = 1;
var CATEGORY_MY_SHOP = 5;
var CATEGORY_TRADE_CENTER = 8;
var CATEGORY_MY_ORGANIZATION = 50;
var CATEGORY_USER_CENTER = 60;
var CATEGORY_MY_ACCOUNT = 70;
var CATEGORY_OTHER = 99;
var CATEGORIES = (_CATEGORIES = {}, _defineProperty(_CATEGORIES, CATEGORY_MY_AGENCY, {
  id: CATEGORY_MY_AGENCY,
  label: '我的旅行社',
  icon: 'travel-agency',
  color: 'blue'
}), _defineProperty(_CATEGORIES, CATEGORY_MY_SHOP, {
  id: CATEGORY_MY_SHOP,
  label: '店铺',
  icon: 'dianpu',
  color: 'red'
}), _defineProperty(_CATEGORIES, CATEGORY_TRADE_CENTER, {
  id: CATEGORY_TRADE_CENTER,
  label: '同业',
  icon: 'peers',
  color: 'green'
}), _defineProperty(_CATEGORIES, CATEGORY_MY_ORGANIZATION, {
  id: CATEGORY_MY_ORGANIZATION,
  label: '组织',
  icon: 'employee',
  color: 'purple'
}), _defineProperty(_CATEGORIES, CATEGORY_USER_CENTER, {
  id: CATEGORY_USER_CENTER,
  label: '套餐',
  icon: 'ucenter',
  color: 'magenta'
}), _defineProperty(_CATEGORIES, CATEGORY_MY_ACCOUNT, {
  id: CATEGORY_MY_ACCOUNT,
  label: '账号',
  icon: 'user',
  color: 'yellow'
}), _defineProperty(_CATEGORIES, CATEGORY_OTHER, {
  id: CATEGORY_OTHER,
  label: '其他',
  icon: '',
  color: 'black'
}), _CATEGORIES);
var STATUS_UNREAD = 0;
var STATUS_READ = 1;
var STATUSES = (_STATUSES = {}, _defineProperty(_STATUSES, STATUS_UNREAD, {
  id: STATUS_UNREAD,
  label: '未读'
}), _defineProperty(_STATUSES, STATUS_READ, {
  id: STATUS_READ,
  label: '已读'
}), _STATUSES);
var SystemMessageData = {
  CATEGORY_MY_AGENCY: CATEGORY_MY_AGENCY,
  CATEGORY_MY_SHOP: CATEGORY_MY_SHOP,
  CATEGORY_TRADE_CENTER: CATEGORY_TRADE_CENTER,
  CATEGORY_MY_ORGANIZATION: CATEGORY_MY_ORGANIZATION,
  CATEGORY_USER_CENTER: CATEGORY_USER_CENTER,
  CATEGORY_MY_ACCOUNT: CATEGORY_MY_ACCOUNT,
  CATEGORY_OTHER: CATEGORY_OTHER,
  CATEGORIES: CATEGORIES,
  STATUS_UNREAD: STATUS_UNREAD,
  STATUS_READ: STATUS_READ,
  STATUSES: STATUSES
};
export default SystemMessageData;