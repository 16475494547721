var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-col",
        {
          attrs: { span: _vm.type === "end" ? _vm.endSpan : _vm.span, xs: 24 },
        },
        [
          _c(
            "div",
            {
              class: [_vm.prefixCls + "-wrapper"],
              style: { justifyContent: _vm.type === "end" ? "flex-end" : "" },
            },
            [
              _vm.label
                ? _c(
                    "span",
                    {
                      class: _vm.computedClass,
                      style: { minWidth: _vm.labelWidth || "68px" },
                    },
                    [_vm._v(_vm._s(_vm.label) + "：")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }