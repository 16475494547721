import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { provinceAndCityData, regionData, regionDataPlus } from 'element-china-area-data';
export default {
  name: 'Address',
  props: {
    placeholder: {
      type: String,
      default: '请选择所在区域'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    },
    value: {
      // 需要填入value ，才可触发 clearable
      type: Array,
      default: function _default() {
        return [];
      }
    },
    type: {
      type: Number,
      default: 1 // 1： 省市区+全部, 2:省市区,3:省市
    }
  },
  data: function data() {
    var optionsMap = {
      1: regionDataPlus,
      2: regionData,
      3: provinceAndCityData
    };
    return {
      options: optionsMap[this.type],
      resetCascader: true
    };
  },
  methods: {
    handleCallback: function handleCallback(val) {
      var _this = this;
      this.callback(val);
      if (val.length === 0) {
        this.resetCascader = false;
        this.$nextTick(function () {
          _this.resetCascader = true;
        });
      }
    }
  }
};