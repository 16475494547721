import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
export default {
  methods: {
    setDisplayInfo: function setDisplayInfo(entityId, displayId) {
      var info = entityId + '_' + displayId;
      console.log(info);
      this.resetSetItem('display_info', info);
    },
    clearDisplayInfo: function clearDisplayInfo() {
      this.resetSetItem('display_info', '');
    },
    getDisplayEntityId: function getDisplayEntityId() {
      var ids = this._getIds();
      return ids ? ids[0] : null;
    },
    getDisplayId: function getDisplayId() {
      var ids = this._getIds();
      return ids ? ids[1] : null;
    },
    _getIds: function _getIds() {
      var info = sessionStorage.getItem('display_info');
      if (info) {
        var ids = info.split('_');
        var eid = ids[0] || null;
        var did = ids[1] || null;
        if (this._isLegalId(eid) && this._isLegalId(did)) {
          return [Number(eid), Number(did)];
        }
      }
      return null;
    },
    _isLegalId: function _isLegalId(id) {
      return id && id !== 'null' && !isNaN(id) && Number(id) > 0;
    }
  }
};