import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _ORDER_STATUSES, _ORDER_CHANGE_TYPES;
//订单类型：销售订单
var ORDER_TYPE_SALES = 1;

//订单类型：采购订单
var ORDER_TYPE_PURCHASES = 2;
var ORDER_DATE_TYPE_ALL = 0;
var ORDER_DATE_TYPE_FUTURE = 1;
var ORDER_DATE_TYPE_PAST = 2;

//备注类型：订单备注
var ORDER_REMARK_TYPE_ORDER = 1;

//备注类型：销售备注
var ORDER_REMARK_TYPE_SALES = 2;

//备注类型：采购备注
var ORDER_REMARK_TYPE_PURCHASE = 3;

//备注类型：操作备注
var ORDER_REMARK_TYPE_OPERATION = 4;

//备注类型：订单备注、操作添加
var ORDER_REMARK_TYPE_OPERATION_ORDER = 5;

// 订单状态
var ORDER_STATUS_NORMAL = 1; // 未处理
var ORDER_STATUS_CANCELLED = 20; // 已取消

var ORDER_STATUSES = (_ORDER_STATUSES = {}, _defineProperty(_ORDER_STATUSES, ORDER_STATUS_NORMAL, {
  id: ORDER_STATUS_NORMAL,
  label: '正常',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_STATUSES, ORDER_STATUS_CANCELLED, {
  id: ORDER_STATUS_CANCELLED,
  label: '取消',
  icon: '',
  color: 'gray'
}), _ORDER_STATUSES);
var ORDER_CHANGE_TYPE_CREATE = 1;
var ORDER_CHANGE_TYPE_UPDATE = 2;
var ORDER_CHANGE_TYPE_CANCEL = 3;
var ORDER_CHANGE_TYPE_DELETE_REMARK = 4;
var ORDER_CHANGE_TYPE_CHANGE_SELLER_USER = 5;
var ORDER_CHANGE_TYPES = (_ORDER_CHANGE_TYPES = {}, _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CREATE, {
  id: ORDER_CHANGE_TYPE_CREATE,
  label: '创建订单',
  icon: '',
  color: 'green'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_UPDATE, {
  id: ORDER_CHANGE_TYPE_UPDATE,
  label: '修改订单',
  icon: '',
  color: 'orange'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CANCEL, {
  id: ORDER_CHANGE_TYPE_CANCEL,
  label: '取消订单',
  icon: '',
  color: 'gray'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_DELETE_REMARK, {
  id: ORDER_CHANGE_TYPE_DELETE_REMARK,
  label: '删除订单备注',
  icon: '',
  color: 'red'
}), _defineProperty(_ORDER_CHANGE_TYPES, ORDER_CHANGE_TYPE_CHANGE_SELLER_USER, {
  id: ORDER_CHANGE_TYPE_CHANGE_SELLER_USER,
  label: '修改负责人',
  icon: '',
  color: 'blue'
}), _ORDER_CHANGE_TYPES);
var SalesOrderData = {
  ORDER_TYPE_SALES: ORDER_TYPE_SALES,
  ORDER_TYPE_PURCHASES: ORDER_TYPE_PURCHASES,
  // 订单状态
  ORDER_STATUS_NORMAL: ORDER_STATUS_NORMAL,
  ORDER_STATUS_CANCELLED: ORDER_STATUS_CANCELLED,
  ORDER_STATUSES: ORDER_STATUSES,
  ORDER_CHANGE_TYPE_CREATE: ORDER_CHANGE_TYPE_CREATE,
  ORDER_CHANGE_TYPE_UPDATE: ORDER_CHANGE_TYPE_UPDATE,
  ORDER_CHANGE_TYPE_CANCEL: ORDER_CHANGE_TYPE_CANCEL,
  ORDER_CHANGE_TYPE_DELETE_REMARK: ORDER_CHANGE_TYPE_DELETE_REMARK,
  ORDER_CHANGE_TYPE_CHANGE_SELLER_USER: ORDER_CHANGE_TYPE_CHANGE_SELLER_USER,
  ORDER_CHANGE_TYPES: ORDER_CHANGE_TYPES,
  ORDER_REMARK_TYPE_ORDER: ORDER_REMARK_TYPE_ORDER,
  ORDER_REMARK_TYPE_SALES: ORDER_REMARK_TYPE_SALES,
  ORDER_REMARK_TYPE_PURCHASE: ORDER_REMARK_TYPE_PURCHASE,
  ORDER_REMARK_TYPE_OPERATION: ORDER_REMARK_TYPE_OPERATION,
  ORDER_REMARK_TYPE_OPERATION_ORDER: ORDER_REMARK_TYPE_OPERATION_ORDER,
  ORDER_DATE_TYPE_ALL: ORDER_DATE_TYPE_ALL,
  ORDER_DATE_TYPE_FUTURE: ORDER_DATE_TYPE_FUTURE,
  ORDER_DATE_TYPE_PAST: ORDER_DATE_TYPE_PAST
};
export default SalesOrderData;