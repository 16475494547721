import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _VERSIONS, _OPERATES;
var SYSTEM_VERSION_FREE = 1;
var SYSTEM_VERSION_BASIC = 2;
var SYSTEM_VERSION_PRIMARY = 3;
var SYSTEM_VERSION_PREMIUM = 4;
var VERSIONS = (_VERSIONS = {}, _defineProperty(_VERSIONS, SYSTEM_VERSION_FREE, {
  id: SYSTEM_VERSION_FREE,
  label: '免费版',
  color: 'gray'
}), _defineProperty(_VERSIONS, SYSTEM_VERSION_BASIC, {
  id: SYSTEM_VERSION_BASIC,
  label: '基础版',
  color: 'green'
}), _defineProperty(_VERSIONS, SYSTEM_VERSION_PRIMARY, {
  id: SYSTEM_VERSION_PRIMARY,
  label: '高级版',
  color: 'orange'
}), _VERSIONS);
var SYSTEM_ACCOUNT_OPERATE_BUY = 1;
var SYSTEM_ACCOUNT_OPERATE_GIFT = 2;
var OPERATES = (_OPERATES = {}, _defineProperty(_OPERATES, SYSTEM_ACCOUNT_OPERATE_BUY, {
  id: SYSTEM_ACCOUNT_OPERATE_BUY,
  label: '购买',
  color: 'blue'
}), _defineProperty(_OPERATES, SYSTEM_ACCOUNT_OPERATE_GIFT, {
  id: SYSTEM_ACCOUNT_OPERATE_GIFT,
  label: '赠送',
  color: 'magenta'
}), _OPERATES);
var SystemVersionData = {
  SYSTEM_VERSION_FREE: SYSTEM_VERSION_FREE,
  SYSTEM_VERSION_BASIC: SYSTEM_VERSION_BASIC,
  SYSTEM_VERSION_PRIMARY: SYSTEM_VERSION_PRIMARY,
  SYSTEM_VERSION_PREMIUM: SYSTEM_VERSION_PREMIUM,
  VERSIONS: VERSIONS,
  SYSTEM_ACCOUNT_OPERATE_BUY: SYSTEM_ACCOUNT_OPERATE_BUY,
  SYSTEM_ACCOUNT_OPERATE_GIFT: SYSTEM_ACCOUNT_OPERATE_GIFT,
  OPERATES: OPERATES
};
export default SystemVersionData;