import _typeof from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterComponentHandler from '@/mixins/filterComponentHandler.js';
export default {
  mixins: [filterComponentHandler],
  props: {
    type: {
      type: Number
    },
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    computedType: function computedType() {
      if (this.type) {
        return this.type;
      }
      if (this.attrConfig.filterDistrictSelectionType) {
        return this.attrConfig.filterDistrictSelectionType;
      }
    },
    emptyValue: function emptyValue() {
      return this.value === undefined || this.value === null || this.value === '' || typeof this.value === 'string' && this.value.length === 0 || _typeof(this.value) === 'object' && this.value.length === 0;
    }
  },
  methods: {
    onDistrictAttributeChange: function onDistrictAttributeChange(val) {
      return this.onAttributeChange(this.attr, val);
    }
  }
};