export default {
  computed: {
    myAgency: function myAgency() {
      return this.$store.state.user.myAgency;
    },
    userInfo: function userInfo() {
      return this.$store.state.user;
    },
    // 组团社
    isGroup: function isGroup() {
      return this.$store.getters.agencyType === 1;
    },
    // 地接社
    isLocalGuide: function isLocalGuide() {
      return this.$store.getters.agencyType === 2;
    },
    // 用户套餐枚举
    systemVersion: function systemVersion() {
      return this.userInfo.systemVersion;
    },
    // 销售职责
    hasSalesDuty: function hasSalesDuty() {
      return this.$store.state.user.hasSalesDuty === 1;
    },
    // 操作职责
    hasOperatorDuty: function hasOperatorDuty() {
      return this.$store.state.user.hasOperatorDuty === 1;
    },
    // 财务职责
    hasAccountingDuty: function hasAccountingDuty() {
      return this.$store.state.user.hasAccountingDuty === 1;
    },
    // 用户套餐名称
    systemVersionName: function systemVersionName() {
      return this.userInfo.systemVersionName;
    },
    // 免费版
    isFreeVersion: function isFreeVersion() {
      return this.systemVersion === 1;
    },
    // 基础版
    isBasicVersion: function isBasicVersion() {
      return this.systemVersion === 2;
    },
    // 高级版
    isPrimaryVersion: function isPrimaryVersion() {
      return this.systemVersion === 3;
    },
    // 未认证
    isNotCertificated: function isNotCertificated() {
      return this.$store.state.user.isCertificated === 0;
    },
    // 已认证
    isCertificated: function isCertificated() {
      return this.$store.state.user.isCertificated === 1;
    },
    // 认证提交状态
    certificateAuditStatus: function certificateAuditStatus() {
      return this.$store.state.user.certificateAuditStatus;
    },
    // 可以有员工账号个数
    totalUserNum: function totalUserNum() {
      return this.userInfo.totalUserNum;
    },
    // 已有员工账号个数
    usedUserNum: function usedUserNum() {
      return this.userInfo.usedUserNum;
    },
    // 空间总大小
    totalDiskSpace: function totalDiskSpace() {
      return this.userInfo.totalDiskSpace;
    },
    // 已使用空间大小
    usedDiskSpace: function usedDiskSpace() {
      return this.userInfo.usedDiskSpace;
    },
    // 空间已使用占比
    usedDiskPercent: function usedDiskPercent() {
      return this.userInfo.usedDiskPercent;
    },
    // 单文件上传大小
    singleFileSize: function singleFileSize() {
      return this.userInfo.singleFileSize;
    },
    // 套餐剩余时间
    leftDateDesc: function leftDateDesc() {
      return this.userInfo.leftDateDesc;
    },
    isMyProductSitePublished: function isMyProductSitePublished() {
      return this.hasPublishedProductSite(this.myAgency);
    }
  },
  methods: {
    isGroupAgency: function isGroupAgency(agency) {
      return agency.agency_type == this.$TravelAgencyData.TYPE_GROUP;
    },
    isLocalGuideAgency: function isLocalGuideAgency(agency) {
      return agency.agency_type == this.$TravelAgencyData.TYPE_LOCAL_GUIDE;
    },
    hasPublishedProductSite: function hasPublishedProductSite(agency) {
      return agency.product_site_status === 1;
    },
    hasAgencyPage: function hasAgencyPage(agency) {
      return this.isPrimaryVersionAgency(agency);
    },
    isFreeVersionAgency: function isFreeVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_FREE;
    },
    isBasicVersionAgency: function isBasicVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_BASIC;
    },
    isPrimaryVersionAgency: function isPrimaryVersionAgency(agency) {
      return agency.system_version === this.$SystemVersionData.SYSTEM_VERSION_PRIMARY;
    },
    isSelfAgencyFun: function isSelfAgencyFun(agency) {
      return agency.id === this.myAgency ? this.myAgency.id : null;
    },
    isInMyResLib: function isInMyResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_IN;
    },
    isNotInMyResLib: function isNotInMyResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_NOT_IN;
    },
    isApplyingToResLib: function isApplyingToResLib(agency) {
      return agency.res_lib_status === this.$TradeData.RES_LIB_STATUS_APPLYING;
    }
  }
};