var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      ref: "detailPanelDrawer",
      attrs: {
        size: _vm.size,
        "append-to-body": true,
        "destroy-on-close": true,
        visible: _vm.showDetailPanel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDetailPanel = $event
        },
      },
    },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm._t(
            "title",
            [
              _c(
                "h2",
                { staticClass: "detail-data-h2" },
                [
                  _c("svg-icon", {
                    staticClass: "detail-title-icon",
                    attrs: { iconClass: _vm.computedIcon },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.computedTitle) +
                      "\n                "
                  ),
                  _vm._t("otherTitle", null, { data: _vm.detailData }),
                ],
                2
              ),
            ],
            { data: _vm.detailData }
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer-main" }, [
        _c(
          "div",
          { staticClass: "detail-content-container" },
          [_vm._t("detailContent", null, { data: _vm.detailData })],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.enableFooterDisplay
        ? _c(
            "div",
            { staticClass: "drawer-footer" },
            [
              _c(
                "el-row",
                { attrs: { type: "flex" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "left-ops", attrs: { span: 2 } },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "info", underline: true },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "right-ops", attrs: { span: 22 } },
                    [
                      _vm._t("opsBeforeEditBtn", null, {
                        data: _vm.detailData,
                      }),
                      _vm._v(" "),
                      _vm.enableEditBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                type: "success",
                                icon: _vm.iconName("el-icon-edit"),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit()
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("opsBetweenEditAndDelBtns", null, {
                        data: _vm.detailData,
                      }),
                      _vm._v(" "),
                      _vm.enableDelBtn
                        ? _c(
                            "el-popconfirm",
                            {
                              attrs: { title: _vm.popconConfirmTitle },
                              on: {
                                confirm: function ($event) {
                                  return _vm.handleDelete()
                                },
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "reference",
                                    size: "mini",
                                    type: "danger",
                                    icon: _vm.iconName("el-icon-delete"),
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._t("opsAfterDelBtns"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }