import "core-js/modules/es6.number.constructor";
//
//

export default {
  props: {
    text: {
      type: [String, Number],
      default: '--'
    },
    cssClass: {
      type: String,
      defualt: 'font-color-gray'
    }
  }
};