import { Entity } from "../Entity";
import FilterParam from "../param/FilterParam";
var attributes = {
  group_no: {
    type: String,
    label: '团号',
    placeholder: '请输入团号',
    filterType: FilterParam.TYPE_NAME
  },
  travel_at: {
    type: Array,
    label: '出发日期',
    filterType: FilterParam.TYPE_DATE_RANGE_PICKER
  },
  product_title: {
    type: String,
    label: '产品',
    placeholder: '请输入产品编号、名称、内部编号',
    filterType: FilterParam.TYPE_NAME
  }
};
var OrderOperationModel = {
  id: Entity.PRODUCT_ORDER_OPERATION,
  name: Entity.PRODUCT_ORDER_OPERATION_NAME,
  label: Entity.PRODUCT_ORDER_OPERATION_LABEL,
  icon: Entity.PRODUCT_ORDER_OPERATION_ICON,
  nameAttr: 'title',
  listUri: '/product/operate',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default OrderOperationModel;