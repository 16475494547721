import { MessageBox } from 'element-ui';
import store from '@/store';
import request from '@/utils/request';

// 抽象基础API
export function fetchList(query, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  if (!expand) {
    expand = 'idCardAFile,idCardBFile,certificateFile,buses,rooms,bankAccounts,contractFiles,districtNames,simpleDistrictName,districts,regions,regions.districtNames,regions.simpleDistrictName,regions.simplestDistrictName,records,records.simpleDistrictName,latestRecord,latestRecord.simpleDistrictName';
  }
  return request({
    url: "".concat(prefix, "/").concat(ng, "?expand=").concat(expand),
    method: 'get',
    params: query
  });
}
export function fetchListItem(id, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  var expand = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  if (!expand) {
    expand = 'idCardAFile,idCardBFile,certificateFile,buses,rooms,bankAccounts,contractFiles,districtNames,simpleDistrictName,districts,regions,regions.districtNames,regions.simpleDistrictName,regions.simplestDistrictName,records,records.districtNames,,latestRecord,latestRecord.districtNames,latestRecord.simpleDistrictName';
  }
  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(id, "?expand=").concat(expand),
    method: 'get'
  });
}
export function updateListItem(data, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(data.id),
    method: 'put',
    data: data.data
  });
}
export function createListItem(data, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng),
    method: 'post',
    data: data
  });
}
export function deleteListItem(id, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/").concat(id),
    method: 'delete'
  });
}
export function deleteAllListItem(ids, ng) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'res';
  return request({
    url: "".concat(prefix, "/").concat(ng, "/batch-delete"),
    method: 'delete',
    data: ids
  });
}
export function margeAgencyListItem(ids) {
  return request({
    url: 'res/travel-agency/merge',
    method: 'post',
    data: ids
  });
}

// 批量导入excel文件 资源
export function postBatchImport(data) {
  return request({
    url: "import",
    method: 'post',
    data: data
  });
}

// 安全相关  获取隐私数据
export function getSid(sid) {
  return request({
    url: 'security/decrypt',
    method: 'post',
    data: {
      sid: sid
    }
  });
}

// 获取隐私数据
export function getRealOriginData(sid) {
  if (!sid) return;
  getSid(sid).then(function (res) {
    MessageBox.alert(res.data.result || '--', '真实数据', {
      customClass: store.state.app.equipment === 'mobile-terminal' ? 'real-origin-alert' : '',
      confirmButtonText: '确定',
      callback: function callback(action) {}
    });
  });
}
export function touch(entityId, $modelId) {
  return request({
    url: "touch?eid=".concat(entityId, "&id=").concat($modelId),
    method: 'get'
  });
}