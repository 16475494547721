//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AppMain',
  computed: {
    key: function key() {
      return this.$route.path;
    },
    equipment: function equipment() {
      return this.$store.state.app.equipment;
    }
  }
};