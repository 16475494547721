import number from "./number";
var install = function install(Vue) {
  Vue.directive('number', number);
};
if (window.Vue) {
  window['number'] = number;
  Vue.use(install); // eslint-disable-line
}

number.install = install;
export default number;