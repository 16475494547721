import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setDisplayColumns as _setDisplayColumns } from '@/api/displayColumns';
export default {
  props: {
    pageCode: {
      type: Number,
      required: true
    },
    displayColumns: {
      type: Object,
      required: true
    },
    toggleIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      displayColumnConfig: this.displayColumns
    };
  },
  methods: {
    setDisplayColumns: function setDisplayColumns(pageCode, data) {
      var traData = {
        config: data
      };
      _setDisplayColumns(pageCode, traData).then(function (res) {
        //this.displayColumns = res.data
      }).catch(function () {});
    },
    getColumnDisplayTagClass: function getColumnDisplayTagClass(name) {
      //console.log(this.displayColumnConfig);
      var config = this.displayColumns[name];
      if (config.value && config.value == 2) {
        return 'multiple-tag';
      } else {
        return 'multiple-tag cursor-p';
      }
    },
    getColumnDisplayTagType: function getColumnDisplayTagType(name) {
      var config = this.displayColumns[name];
      if (config && config.value > 0) {
        return 'success';
      } else {
        return 'info';
      }
    },
    getColumnDisplayTagEffect: function getColumnDisplayTagEffect(name) {
      var config = this.displayColumns[name];
      if (config) {
        if (config.value == 2) {
          return 'dark';
        } else {
          return 'light';
        }
      }
    },
    configColumnDisplay: function configColumnDisplay(name) {
      var config = this.displayColumns[name];
      if (config) {
        if (config.value == 2) {
          return;
        }
        if (config.value == 1) {
          config.value = 0;
        } else if (config.value == 0) {
          config.value = 1;
        }
        this.setDisplayColumns(this.pageCode, _defineProperty({}, name, config.value));
        this.$emit('update:displayColumns', this.displayColumns);
        this.$emit('upToggleIndex', this.toggleIndex + 1);
      }
    }
  }
};