// filter

// 表单类型
var TYPE_NAME = 1;
var TYPE_SINGLE_SELECTION = 2;
var TYPE_DISTRICT_SELECTION = 3;
var TYPE_CHECK = 4;
var TYPE_DATE_RANGE_PICKER = 5;
var OPTIONS_USER_ID_NAME = 1;
var OPTIONS_DEPARTMENT_ID_NAME = 2;
var LEAD_TRAVELLER_NAME = 1;
var SELLER_USER_ID = 1;
var BUYER_USER_ID = 1;
var ATTR_TYPE_STR = 1;
var ATTR_TYPE_NUM_EXCEPT_ZERO = 2;
var ATTR_TYPE_NUM_ALLOWED_ZERO = 3;
var ATTR_TYPE_ARR = 4;
var FilterParam = {
  TYPE_NAME: TYPE_NAME,
  TYPE_SINGLE_SELECTION: TYPE_SINGLE_SELECTION,
  TYPE_DISTRICT_SELECTION: TYPE_DISTRICT_SELECTION,
  TYPE_DATE_RANGE_PICKER: TYPE_DATE_RANGE_PICKER,
  TYPE_CHECK: TYPE_CHECK,
  ATTR_TYPE_STR: ATTR_TYPE_STR,
  ATTR_TYPE_NUM_EXCEPT_ZERO: ATTR_TYPE_NUM_EXCEPT_ZERO,
  ATTR_TYPE_NUM_ALLOWED_ZERO: ATTR_TYPE_NUM_ALLOWED_ZERO,
  ATTR_TYPE_ARR: ATTR_TYPE_ARR,
  OPTIONS_USER_ID_NAME: OPTIONS_USER_ID_NAME,
  OPTIONS_DEPARTMENT_ID_NAME: OPTIONS_DEPARTMENT_ID_NAME,
  LEAD_TRAVELLER_NAME: LEAD_TRAVELLER_NAME,
  SELLER_USER_ID: SELLER_USER_ID,
  BUYER_USER_ID: BUYER_USER_ID
};
export default FilterParam;