var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addproduct" },
    [
      _c(
        "el-dropdown",
        { attrs: { trigger: "hover", placement: "bottom" } },
        [
          _c(
            "div",
            {
              staticClass: "el-dropdown-link",
              staticStyle: { cursor: "pointer" },
            },
            [
              _c("i", {
                staticClass: "el-icon-plus",
                staticStyle: {
                  "vertical-align": "middle",
                  fontSize: "20px",
                  "margin-right": "10px",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _vm._l(_vm.list, function (item, index) {
                return _c("el-dropdown-item", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "el-dropdown-item",
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(item.link)
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticStyle: { "margin-right": "6px" },
                        attrs: {
                          "icon-class": item.iconName,
                          "class-name": "back",
                        },
                      }),
                      _vm._v(" " + _vm._s(item.contText) + "\n          "),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }