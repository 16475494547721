import "core-js/modules/es6.number.constructor";
import { Entity } from "../Entity";
import Common from "../Common";
import FilterParam from "../param/FilterParam";
import AgencyProductData from "../data/AgencyProductData";
var attributes = {
  title: {
    type: String,
    label: '名称',
    placeholder: '请输入产品名称、编号',
    filterType: FilterParam.TYPE_NAME
  },
  category: {
    type: Number,
    label: '类型',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.CATEGORIES
  },
  status: {
    type: Number,
    label: '发布',
    placeholder: '请选择产品发布状态',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: AgencyProductData.PUBLISH_STATUSES
  },
  is_cloned: {
    type: Number,
    label: '克隆',
    placeholder: '请选择是否为克隆产品',
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    filterOptions: Common.YES_OR_NO
  },
  operator_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '操作负责人',
    filterOptionType: FilterParam.OPTIONS_USER_ID_NAME
  }
};
var AgencyScheduleProductModel = {
  id: Entity.AGENCY_PRODUCT,
  name: Entity.AGENCY_PRODUCT_NAME,
  label: Entity.AGENCY_PRODUCT_LABEL,
  icon: Entity.AGENCY_PRODUCT_ICON,
  nameAttr: 'title',
  listUri: '/product/schedule',
  createUri: null,
  updateUri: null,
  attributes: attributes
};
export default AgencyScheduleProductModel;