import request from '@/utils/request';
export function getDisplayColumns(id) {
  return request({
    url: "account/user-custom-config/display-column/".concat(id),
    method: 'get'
  });
}
export function setDisplayColumns(id, data) {
  return request({
    url: "account/user-custom-config/display-column/".concat(id),
    method: 'post',
    data: data
  });
}