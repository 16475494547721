import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _RES_LIB_STATUSES, _APPLICATION_BYS, _APPLICATION_AUDIT_ST, _DISPLAY_IN_TRADE_STA;
import Common from "../Common";
var RES_LIB_STATUS_NOT_IN = 1;
var RES_LIB_STATUS_APPLYING = 2;
var RES_LIB_STATUS_IN = 3;
var RES_LIB_STATUSES = (_RES_LIB_STATUSES = {}, _defineProperty(_RES_LIB_STATUSES, RES_LIB_STATUS_NOT_IN, {
  id: RES_LIB_STATUS_NOT_IN,
  label: '未添加',
  icon: 'el-icon-remove',
  color: 'gray'
}), _defineProperty(_RES_LIB_STATUSES, RES_LIB_STATUS_APPLYING, {
  id: RES_LIB_STATUS_APPLYING,
  label: '已申请',
  icon: 'el-icon-warning',
  color: 'blue'
}), _defineProperty(_RES_LIB_STATUSES, RES_LIB_STATUS_IN, {
  id: RES_LIB_STATUS_IN,
  label: '已添加',
  icon: 'el-icon-success',
  color: 'green'
}), _RES_LIB_STATUSES);
var APPLICATION_SENDER = 1;
var APPLICATION_RECEIVER = 2;
var APPLICATION_TYPE_ADD_TO_RES_LIB = 1;
var APPLICATION_TYPES = _defineProperty({}, APPLICATION_TYPE_ADD_TO_RES_LIB, {
  id: APPLICATION_TYPE_ADD_TO_RES_LIB,
  label: '添加到资源库',
  icon: 'resource',
  color: 'black'
});
var APPLICATION_BYS = (_APPLICATION_BYS = {}, _defineProperty(_APPLICATION_BYS, APPLICATION_SENDER, {
  id: APPLICATION_SENDER,
  label: '我发起的'
}), _defineProperty(_APPLICATION_BYS, APPLICATION_RECEIVER, {
  id: APPLICATION_RECEIVER,
  label: '我接收的'
}), _APPLICATION_BYS);
var APPLICATION_AUDIT_STATUS_WAITTING = Common.AUDIT_STATUS_WAITTING;
var APPLICATION_AUDIT_STATUS_PASS = Common.AUDIT_STATUS_PASS;
var APPLICATION_AUDIT_STATUSES = (_APPLICATION_AUDIT_ST = {}, _defineProperty(_APPLICATION_AUDIT_ST, APPLICATION_AUDIT_STATUS_WAITTING, Common.AUDIT_STATUSES[Common.AUDIT_STATUS_WAITTING]), _defineProperty(_APPLICATION_AUDIT_ST, APPLICATION_AUDIT_STATUS_PASS, Common.AUDIT_STATUSES[Common.AUDIT_STATUS_PASS]), _APPLICATION_AUDIT_ST);
var DISPLAY_IN_TRADE_STATUS_NO = Common.NO;
var DISPLAY_IN_TRADE_STATUS_YES = Common.YES;
var DISPLAY_IN_TRADE_STATUSES = (_DISPLAY_IN_TRADE_STA = {}, _defineProperty(_DISPLAY_IN_TRADE_STA, DISPLAY_IN_TRADE_STATUS_NO, {
  label: '不展示',
  color: 'red'
}), _defineProperty(_DISPLAY_IN_TRADE_STA, DISPLAY_IN_TRADE_STATUS_YES, {
  label: '展示',
  color: 'green'
}), _DISPLAY_IN_TRADE_STA);
var TradeData = {
  RES_LIB_STATUS_NOT_IN: RES_LIB_STATUS_NOT_IN,
  RES_LIB_STATUS_APPLYING: RES_LIB_STATUS_APPLYING,
  RES_LIB_STATUS_IN: RES_LIB_STATUS_IN,
  RES_LIB_STATUSES: RES_LIB_STATUSES,
  APPLICATION_TYPE_ADD_TO_RES_LIB: APPLICATION_TYPE_ADD_TO_RES_LIB,
  APPLICATION_TYPES: APPLICATION_TYPES,
  APPLICATION_AUDIT_STATUS_WAITTING: APPLICATION_AUDIT_STATUS_WAITTING,
  APPLICATION_AUDIT_STATUS_PASS: APPLICATION_AUDIT_STATUS_PASS,
  APPLICATION_AUDIT_STATUSES: APPLICATION_AUDIT_STATUSES,
  APPLICATION_SENDER: APPLICATION_SENDER,
  APPLICATION_RECEIVER: APPLICATION_RECEIVER,
  APPLICATION_BYS: APPLICATION_BYS,
  DISPLAY_IN_TRADE_STATUS_NO: DISPLAY_IN_TRADE_STATUS_NO,
  DISPLAY_IN_TRADE_STATUS_YES: DISPLAY_IN_TRADE_STATUS_YES,
  DISPLAY_IN_TRADE_STATUSES: DISPLAY_IN_TRADE_STATUSES
};
export default TradeData;