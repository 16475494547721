var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_c("router-view", { key: _vm.key })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _vm.equipment !== "mobile-terminal"
          ? _c("div", [_vm._m(0)])
          : _c("div", [
              _c("p", [_vm._v("Copyright © 2021")]),
              _vm._v(" "),
              _c("p", { staticStyle: { margin: "3px 0" } }, [
                _vm._v("上海山海通网络信息科技有限公司"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://beian.miit.gov.cn/#/Integrated/index",
                    target: "_blank",
                  },
                },
                [_vm._v("沪ICP备2021033081号")]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "margin-bottom": "12px" } }, [
      _vm._v(
        "\n                Copyright © 2021 上海山海通网络信息科技有限公司 |\n                "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://beian.miit.gov.cn/#/Integrated/index",
            target: "_blank",
          },
        },
        [_vm._v("沪ICP备2021033081号")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }