import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _ResEntityData;
/**
 * res
 */
var RES_HOTEL = 1;
var RES_HOTEL_NAME = 'hotel';
var RES_HOTEL_ICON = 'hotel';
var RES_HOTEL_LABEL = '酒店';
var RES_LANDSCAPE = 2;
var RES_LANDSCAPE_LABEL = '景区';
var RES_LANDSCAPE_NAME = 'landscape';
var RES_LANDSCAPE_ICON = 'landscape';
var RES_BUS_COMPANY = 3;
var RES_BUS_COMPANY_NAME = 'bus-company';
var RES_BUS_COMPANY_LABEL = '车队';
var RES_BUS_COMPANY_ICON = 'bus';
var RES_RESTAURANT = 4;
var RES_RESTAURANT_NAME = 'restaurant';
var RES_RESTAURANT_LABEL = '餐厅';
var RES_RESTAURANT_ICON = 'restaurant';
var RES_SHOP = 5;
var RES_SHOP_NAME = 'shop';
var RES_SHOP_LABEL = '商店';
var RES_SHOP_ICON = 'shop';
var RES_TOUR_GUIDE = 6;
var RES_TOUR_GUIDE_NAME = 'tour-guide';
var RES_TOUR_GUIDE_LABEL = '导游';
var RES_TOUR_GUIDE_ICON = 'guide';
var RES_TRAVEL_AGENCY = 7;
var RES_TRAVEL_AGENCY_NAME = 'travel-agency';
var RES_TRAVEL_AGENCY_LABEL = '旅行社';
var RES_TRAVEL_AGENCY_ICON = 'travel-agency';
var RES_TRAVELLER = 8;
var RES_TRAVELLER_NAME = 'traveller';
var RES_TRAVELLER_ICON = 'tourist';
var RES_TRAVELLER_LABEL = '游客';

/** traveller export */
var RES_TRAVELLER_EXPORT = 14;
var RES_TRAVELLER_EXPORT_NAME = 'traveller-export';
var RES_TRAVELLER_EXPORT_LABEL = '游客制表';
var RES_TRAVELLER_EXPORT_ICON = 'table';

/** agency product */
var AGENCY_PRODUCT = 18;
var AGENCY_PRODUCT_NAME = 'product';
var AGENCY_PRODUCT_LABEL = '线路产品';
var AGENCY_PRODUCT_ICON = 'product';

/** agency trade product */
var AGENCY_TRADE_PRODUCT = 180;
var AGENCY_TRADE_PRODUCT_NAME = 'product';
var AGENCY_TRADE_PRODUCT_LABEL = '线路产品';
var AGENCY_TRADE_PRODUCT_ICON = 'product';

/** agency schedule product */
var AGENCY_SCHEDULE_PRODUCT = 181;
var AGENCY_SCHEDULE_PRODUCT_NAME = 'schedule-product';
var AGENCY_SCHEDULE_PRODUCT_LABEL = '线路产品';
var AGENCY_SCHEDULE_PRODUCT_ICON = 'product';

/** 销售订单 */
var SALES_ORDER = 39;
var SALES_ORDER_NAME = 'sales-order';
var SALES_ORDER_LABEL = '销售订单';
var SALES_ORDER_ICON = 'order2';

/** 采购订单 */
var BUYER_ORDER = 39;
var BUYER_ORDER_NAME = 'product';
var BUYER_ORDER_LABEL = '采购订单';
var BUYER_ORDER_ICON = 'product';

/** order operation*/
var PRODUCT_ORDER_OPERATION = 88;
var PRODUCT_ORDER_OPERATION_NAME = 'oder-operation';
var PRODUCT_ORDER_OPERATION_LABEL = '操作派团';
var PRODUCT_ORDER_OPERATION_ICON = 'order-operate';

/** file */
var FILE = 26;
var FILE_NAME = 'file';
var FILE_LABEL = '文件';
var FILE_ICON = 'file';

/** account */
var ACCOUNT_USER = 20;
var ACCOUNT_USER_NAME = 'user';
var ACCOUNT_USER_LABEL = '员工';
var ACCOUNT_USER_ICON = 'account';
var ACCOUNT_DEPARTMENT = 23;
var ACCOUNT_DEPARTMENT_NAME = 'department';
var ACCOUNT_DEPARTMENT_LABEL = '部门';
var ACCOUNT_DEPARTMENT_ICON = 'department';
var ACCOUNT_SYSTEM_ORDER = 30;
var ACCOUNT_SYSTEM_ORDER_NAME = 'order';
var ACCOUNT_SYSTEM_ORDER_LABEL = '订单';
var ACCOUNT_SYSTEM_ORDER_ICON = 'order';
var ACCOUNT_SYSTEM_MESSAGE = 24;
var ACCOUNT_SYSTEM_MESSAGE_NAME = 'message';
var ACCOUNT_SYSTEM_MESSAGE_LABEL = '消息';
var ACCOUNT_SYSTEM_MESSAGE_ICON = 'message';

/** recycle */
var RECYCLE = 17;
var RECYCLE_NAME = 'recycle';
var RECYCLE_LABEL = '回收站';
var RECYCLE_ICON = 'delete';

/** trade */
var TRADE_AGENCY = 40;
var TRADE_AGENCY_NAME = 'trade-agency';
var TRADE_AGENCY_LABEL = '同业旅行社';
var TRADE_AGENCY_ICON = 'travel-agency';
var TRADE_APPLICATION = 36;
var TRADE_APPLICATION_NAME = 'trade-application';
var TRADE_APPLICATION_LABEL = '同业申请';
var TRADE_APPLICATION_ICON = 'apply';
function data(id, name, label, icon) {
  return {
    id: id,
    name: name,
    label: label,
    icon: icon
  };
}
export var Entity = {
  RES_HOTEL: RES_HOTEL,
  RES_HOTEL_NAME: RES_HOTEL_NAME,
  RES_HOTEL_LABEL: RES_HOTEL_LABEL,
  RES_HOTEL_ICON: RES_HOTEL_ICON,
  RES_HOTEL_DATA: data(RES_HOTEL, RES_HOTEL_NAME, RES_HOTEL_LABEL, RES_HOTEL_ICON),
  RES_LANDSCAPE: RES_LANDSCAPE,
  RES_LANDSCAPE_NAME: RES_LANDSCAPE_NAME,
  RES_LANDSCAPE_ICON: RES_LANDSCAPE_ICON,
  RES_LANDSCAPE_LABEL: RES_LANDSCAPE_LABEL,
  RES_LANDSCAPE_DATA: data(RES_LANDSCAPE, RES_LANDSCAPE_NAME, RES_LANDSCAPE_LABEL, RES_LANDSCAPE_ICON),
  RES_BUS_COMPANY: RES_BUS_COMPANY,
  RES_BUS_COMPANY_NAME: RES_BUS_COMPANY_NAME,
  RES_BUS_COMPANY_ICON: RES_BUS_COMPANY_ICON,
  RES_BUS_COMPANY_LABEL: RES_BUS_COMPANY_LABEL,
  RES_BUS_COMPANY_DATA: data(RES_BUS_COMPANY, RES_BUS_COMPANY_NAME, RES_BUS_COMPANY_LABEL, RES_BUS_COMPANY_ICON),
  RES_RESTAURANT: RES_RESTAURANT,
  RES_RESTAURANT_NAME: RES_RESTAURANT_NAME,
  RES_RESTAURANT_ICON: RES_RESTAURANT_ICON,
  RES_RESTAURANT_LABEL: RES_RESTAURANT_LABEL,
  RES_RESTAURANT_DATA: data(RES_RESTAURANT, RES_RESTAURANT_NAME, RES_RESTAURANT_LABEL, RES_RESTAURANT_ICON),
  RES_SHOP: RES_SHOP,
  RES_SHOP_NAME: RES_SHOP_NAME,
  RES_SHOP_ICON: RES_SHOP_ICON,
  RES_SHOP_LABEL: RES_SHOP_LABEL,
  RES_SHOP_DATA: data(RES_SHOP, RES_SHOP_NAME, RES_SHOP_LABEL, RES_SHOP_ICON),
  RES_TOUR_GUIDE: RES_TOUR_GUIDE,
  RES_TOUR_GUIDE_NAME: RES_TOUR_GUIDE_NAME,
  RES_TOUR_GUIDE_LABEL: RES_TOUR_GUIDE_LABEL,
  RES_TOUR_GUIDE_ICON: RES_TOUR_GUIDE_ICON,
  RES_TOUR_GUIDE_DATA: data(RES_TOUR_GUIDE, RES_TOUR_GUIDE_NAME, RES_TOUR_GUIDE_LABEL, RES_TOUR_GUIDE_ICON),
  RES_TRAVEL_AGENCY: RES_TRAVEL_AGENCY,
  RES_TRAVEL_AGENCY_NAME: RES_TRAVEL_AGENCY_NAME,
  RES_TRAVEL_AGENCY_LABEL: RES_TRAVEL_AGENCY_LABEL,
  RES_TRAVEL_AGENCY_ICON: RES_TRAVEL_AGENCY_ICON,
  RES_TRAVEL_AGENCY_DATA: data(RES_TRAVEL_AGENCY, RES_TRAVEL_AGENCY_NAME, RES_TRAVEL_AGENCY_LABEL, RES_TRAVEL_AGENCY_ICON),
  RES_TRAVELLER: RES_TRAVELLER,
  RES_TRAVELLER_NAME: RES_TRAVELLER_NAME,
  RES_TRAVELLER_ICON: RES_TRAVELLER_ICON,
  RES_TRAVELLER_LABEL: RES_TRAVELLER_LABEL,
  RES_TRAVELLER_DATA: data(RES_TRAVELLER, RES_TRAVELLER_NAME, RES_TRAVELLER_LABEL, RES_TRAVELLER_ICON),
  RES_TRAVELLER_EXPORT: RES_TRAVELLER_EXPORT,
  RES_TRAVELLER_EXPORT_NAME: RES_TRAVELLER_EXPORT_NAME,
  RES_TRAVELLER_EXPORT_ICON: RES_TRAVELLER_EXPORT_ICON,
  RES_TRAVELLER_EXPORT_LABEL: RES_TRAVELLER_EXPORT_LABEL,
  RES_TRAVELLER_EXPORT_DATA: data(RES_TRAVELLER_EXPORT, RES_TRAVELLER_EXPORT_NAME, RES_TRAVELLER_EXPORT_LABEL, RES_TRAVELLER_EXPORT_ICON),
  AGENCY_PRODUCT: AGENCY_PRODUCT,
  AGENCY_PRODUCT_NAME: AGENCY_PRODUCT_NAME,
  AGENCY_PRODUCT_LABEL: AGENCY_PRODUCT_LABEL,
  AGENCY_PRODUCT_ICON: AGENCY_PRODUCT_ICON,
  AGENCY_PRODUCT_DATA: data(AGENCY_PRODUCT, AGENCY_PRODUCT_NAME, AGENCY_PRODUCT_LABEL, AGENCY_PRODUCT_ICON),
  AGENCY_TRADE_PRODUCT: AGENCY_TRADE_PRODUCT,
  AGENCY_TRADE_PRODUCT_NAME: AGENCY_TRADE_PRODUCT_NAME,
  AGENCY_TRADE_PRODUCT_LABEL: AGENCY_TRADE_PRODUCT_LABEL,
  AGENCY_TRADE_PRODUCT_ICON: AGENCY_TRADE_PRODUCT_ICON,
  AGENCY_TRADE_PRODUCT_DATA: data(AGENCY_TRADE_PRODUCT, AGENCY_TRADE_PRODUCT_NAME, AGENCY_TRADE_PRODUCT_LABEL, AGENCY_TRADE_PRODUCT_ICON),
  AGENCY_SCHEDULE_PRODUCT: AGENCY_SCHEDULE_PRODUCT,
  AGENCY_SCHEDULE_PRODUCT_NAME: AGENCY_SCHEDULE_PRODUCT_NAME,
  AGENCY_SCHEDULE_PRODUCT_LABEL: AGENCY_SCHEDULE_PRODUCT_LABEL,
  AGENCY_SCHEDULE_PRODUCT_ICON: AGENCY_SCHEDULE_PRODUCT_ICON,
  AGENCY_SCHEDULE_PRODUCT_DATA: data(AGENCY_SCHEDULE_PRODUCT, AGENCY_SCHEDULE_PRODUCT_NAME, AGENCY_SCHEDULE_PRODUCT_LABEL, AGENCY_SCHEDULE_PRODUCT_ICON),
  SALES_ORDER: SALES_ORDER,
  SALES_ORDER_NAME: SALES_ORDER_NAME,
  SALES_ORDER_LABEL: SALES_ORDER_LABEL,
  SALES_ORDER_ICON: SALES_ORDER_ICON,
  SALES_ORDER_DATA: data(SALES_ORDER, SALES_ORDER_NAME, SALES_ORDER_LABEL, SALES_ORDER_ICON),
  BUYER_ORDER: BUYER_ORDER,
  BUYER_ORDER_NAME: BUYER_ORDER_NAME,
  BUYER_ORDER_LABEL: BUYER_ORDER_LABEL,
  BUYER_ORDER_ICON: BUYER_ORDER_ICON,
  BUYER_ORDER_DATA: data(BUYER_ORDER, BUYER_ORDER_NAME, BUYER_ORDER_LABEL, BUYER_ORDER_ICON),
  PRODUCT_ORDER_OPERATION: PRODUCT_ORDER_OPERATION,
  PRODUCT_ORDER_OPERATION_NAME: PRODUCT_ORDER_OPERATION_NAME,
  PRODUCT_ORDER_OPERATION_LABEL: PRODUCT_ORDER_OPERATION_LABEL,
  PRODUCT_ORDER_OPERATION_ICON: PRODUCT_ORDER_OPERATION_ICON,
  PRODUCT_ORDER_OPERATION_DATA: data(PRODUCT_ORDER_OPERATION, PRODUCT_ORDER_OPERATION_NAME, PRODUCT_ORDER_OPERATION_LABEL, PRODUCT_ORDER_OPERATION_ICON),
  FILE: FILE,
  FILE_LABEL: FILE_LABEL,
  FILE_NAME: FILE_NAME,
  FILE_ICON: FILE_ICON,
  FILE_DATA: data(FILE, FILE_NAME, FILE_LABEL, FILE_ICON),
  ACCOUNT_USER: ACCOUNT_USER,
  ACCOUNT_USER_LABEL: ACCOUNT_USER_LABEL,
  ACCOUNT_USER_NAME: ACCOUNT_USER_NAME,
  ACCOUNT_USER_ICON: ACCOUNT_USER_ICON,
  ACCOUNT_USER_DATA: data(ACCOUNT_USER, ACCOUNT_USER_NAME, ACCOUNT_USER_LABEL, ACCOUNT_USER_ICON),
  ACCOUNT_DEPARTMENT: ACCOUNT_DEPARTMENT,
  ACCOUNT_DEPARTMENT_LABEL: ACCOUNT_DEPARTMENT_LABEL,
  ACCOUNT_DEPARTMENT_NAME: ACCOUNT_DEPARTMENT_NAME,
  ACCOUNT_DEPARTMENT_ICON: ACCOUNT_DEPARTMENT_ICON,
  ACCOUNT_DEPARTMENT_DATA: data(ACCOUNT_DEPARTMENT, ACCOUNT_DEPARTMENT_NAME, ACCOUNT_DEPARTMENT_LABEL, ACCOUNT_DEPARTMENT_ICON),
  ACCOUNT_SYSTEM_ORDER: ACCOUNT_SYSTEM_ORDER,
  ACCOUNT_SYSTEM_ORDER_LABEL: ACCOUNT_SYSTEM_ORDER_LABEL,
  ACCOUNT_SYSTEM_ORDER_NAME: ACCOUNT_SYSTEM_ORDER_NAME,
  ACCOUNT_SYSTEM_ORDER_ICON: ACCOUNT_SYSTEM_ORDER_ICON,
  ACCOUNT_SYSTEM_ORDER_DATA: data(ACCOUNT_SYSTEM_ORDER, ACCOUNT_SYSTEM_ORDER_NAME, ACCOUNT_SYSTEM_ORDER_LABEL, ACCOUNT_SYSTEM_ORDER_ICON),
  ACCOUNT_SYSTEM_MESSAGE: ACCOUNT_SYSTEM_MESSAGE,
  ACCOUNT_SYSTEM_MESSAGE_LABEL: ACCOUNT_SYSTEM_MESSAGE_LABEL,
  ACCOUNT_SYSTEM_MESSAGE_NAME: ACCOUNT_SYSTEM_MESSAGE_NAME,
  ACCOUNT_SYSTEM_MESSAGE_ICON: ACCOUNT_SYSTEM_MESSAGE_ICON,
  ACCOUNT_SYSTEM_MESSAGE_DATA: data(ACCOUNT_SYSTEM_MESSAGE, ACCOUNT_SYSTEM_MESSAGE_NAME, ACCOUNT_SYSTEM_MESSAGE_LABEL, ACCOUNT_SYSTEM_MESSAGE_ICON),
  RECYCLE: RECYCLE,
  RECYCLE_LABEL: RECYCLE_LABEL,
  RECYCLE_NAME: RECYCLE_NAME,
  RECYCLE_ICON: RECYCLE_ICON,
  RECYCLE_DATA: data(RECYCLE, RECYCLE_NAME, RECYCLE_LABEL, RECYCLE_ICON),
  TRADE_AGENCY: TRADE_AGENCY,
  TRADE_AGENCY_LABEL: TRADE_AGENCY_LABEL,
  TRADE_AGENCY_NAME: TRADE_AGENCY_NAME,
  TRADE_AGENCY_ICON: TRADE_AGENCY_ICON,
  TRADE_AGENCY_DATA: data(TRADE_AGENCY, TRADE_AGENCY_NAME, TRADE_AGENCY_LABEL, TRADE_AGENCY_ICON),
  TRADE_APPLICATION: TRADE_APPLICATION,
  TRADE_APPLICATION_LABEL: TRADE_APPLICATION_LABEL,
  TRADE_APPLICATION_NAME: TRADE_APPLICATION_NAME,
  TRADE_APPLICATION_ICON: TRADE_APPLICATION_ICON,
  TRADE_APPLICATION_DATA: data(TRADE_APPLICATION, TRADE_APPLICATION_NAME, TRADE_APPLICATION_LABEL, TRADE_APPLICATION_ICON)
};
export var ResEntityData = (_ResEntityData = {}, _defineProperty(_ResEntityData, RES_RESTAURANT, Entity.RES_RESTAURANT_DATA), _defineProperty(_ResEntityData, RES_HOTEL, Entity.RES_HOTEL_DATA), _defineProperty(_ResEntityData, RES_LANDSCAPE, Entity.RES_LANDSCAPE_DATA), _defineProperty(_ResEntityData, RES_BUS_COMPANY, Entity.RES_BUS_COMPANY_DATA), _defineProperty(_ResEntityData, RES_SHOP, Entity.RES_SHOP_DATA), _defineProperty(_ResEntityData, RES_TOUR_GUIDE, Entity.RES_TOUR_GUIDE_DATA), _defineProperty(_ResEntityData, RES_TRAVEL_AGENCY, Entity.RES_TRAVEL_AGENCY_DATA), _defineProperty(_ResEntityData, RES_TRAVELLER, Entity.RES_TRAVELLER_DATA), _ResEntityData);