import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'data'
    },
    label: {
      type: String,
      default: '暂无数据'
    },
    size: {
      type: [String, Number],
      default: 45
    },
    height: {
      type: [String, Number],
      default: 60
    }
  },
  computed: {
    heightStyle: function heightStyle() {
      return 'margin: ' + this.height + 'px';
    }
  }
};