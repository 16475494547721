//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    listClasses: {
      type: [Array, String],
      default: ''
    },
    listLoading: {
      type: Boolean,
      default: false
    },
    emptyListDesc: {
      type: String,
      default: ''
    },
    emptyImageIcon: {
      type: String,
      default: ''
    }
  }
};