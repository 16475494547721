import request from '@/utils/request';
export function getList(params) {
  return request({
    url: 'account/message',
    method: 'get',
    params: params
  });
}
// 设置已读
export function read(ids) {
  return request({
    url: "account/message/read",
    method: 'post',
    data: {
      ids: ids
    }
  });
}
// 设置全部已读
export function readAll() {
  return request({
    url: "account/message/read-all",
    method: 'post'
  });
}
// 删除消息
export function del(ids) {
  return request({
    url: "account/message/remove",
    method: 'delete',
    data: ids
  });
}
// 删除所有消息
export function delAll() {
  return request({
    url: "account/message/remove-all",
    method: 'delete'
  });
}
// 获取未读消息总数
export function getMessageUnreadCount() {
  return request({
    url: "account/message/unread-count",
    method: 'get'
  });
}