import "core-js/modules/es6.number.constructor";
import { Entity } from "../../Entity";
import FilterParam from "../../param/FilterParam";
import Common from "../../Common";
import AccountData from "../../data/AccountData";
var attributes = {
  nameMobile: {
    type: String,
    label: '关键字',
    filterType: FilterParam.TYPE_NAME,
    placeholder: '请输入姓名或手机号前7位'
  },
  gender: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '性别',
    filterOptions: Common.GENDERS
  },
  department_id: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '部门',
    filterOptionType: FilterParam.OPTIONS_DEPARTMENT_ID_NAME
  },
  duty: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '职责',
    filterOptions: AccountData.USER_DUTIES
  },
  role: {
    type: Number,
    filterType: FilterParam.TYPE_SINGLE_SELECTION,
    label: '角色',
    filterOptions: AccountData.USER_ROLES
  }
};
var UserModel = {
  id: Entity.ACCOUNT_USER,
  name: Entity.ACCOUNT_USER_NAME,
  label: Entity.ACCOUNT_USER_LABEL,
  icon: Entity.ACCOUNT_USER_ICON,
  nameAttr: 'name',
  listUri: '/employee/employees',
  createUri: '/employee/employees/add',
  updateUri: '/employee/employees/edit',
  attributes: attributes
};
export default UserModel;