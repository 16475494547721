import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//

export default {
  props: {
    data: [String, Number]
  }
};