//
//
//
//
//
//
//
//

export default {
  name: 'ConditionPanel',
  props: {
    labelWidth: {
      type: String,
      default: '85px'
    }
  },
  data: function data() {
    return {};
  },
  provide: function provide() {
    var slotItems = this.$slots.default.filter(function (item) {
      return item.tag;
    });
    var maxTextlength = Math.max.apply(null, slotItems.map(function (item) {
      return (
        //item.componentOptions.propsData.label ?
        //item.componentOptions.propsData.label.length : 0
        0
      );
    }));
    return {
      span: 8,
      slotItems: slotItems,
      // endSpan: (slotItems.length % 3 ? 4 - (slotItems.length % 3) : 1) * 8,
      // labelWidth: 15 * maxTextlength + 'px'
      labelWidth: this.labelWidth
    };
  }
};