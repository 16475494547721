import _typeof from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import filterComponentHandler from '@/mixins/filterComponentHandler.js';
export default {
  mixins: [filterComponentHandler],
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    emptyValue: function emptyValue() {
      return this.value === undefined || this.value === null || this.value === '' || typeof this.value === 'string' && this.value.length === 0 || _typeof(this.value) === 'object' && this.value.length === 0;
    }
  },
  methods: {
    onDatePickerChange: function onDatePickerChange(val) {
      return this.onAttributeChange(this.attr, val);
    }
  }
};