import _defineProperty from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _Models;
import { Entity } from "./Entity";
import Common from "./Common";

//data
import ResData from "./data/ResData";
import FileData from "./data/FileData";
import AgencyProductData from "./data/AgencyProductData";
import SalesOrderData from "./data/SalesOrderData";
import TravelAgencyData from "./data/TravelAgencyData";
import TradeData from "./data/TradeData";
import RecycleData from "./data/RecycleData";
import SystemVersionData from "./data/SystemVersionData";
import SystemOrderData from "./data/SystemOrderData";
import SystemMessageData from "./data/SystemMessageData";
import AccountData from "./data/AccountData";
import MetaData from "./data/MetaData";

//model
import AgencyProductModel from "./model/AgencyProductModel";
import AgencyScheduleProductModel from "./model/AgencyScheduleProductModel";
import SalesOrderModel from "./model/SalesOrderModel";
import BuyerOrderModel from "./model/BuyerOrderModel";
import OrderOperationModel from "./model/OrderOperationModel";
import ResTravelAgencyModel from "./model/res/ResTravelAgencyModel";
import ResHotelModel from "./model/res/ResHotelModel";
import ResLandscapeModel from "./model/res/ResLandscapeModel";
import ResTourGuideModel from "./model/res/ResTourGuideModel";
import ResBusCompanyModel from "./model/res/ResBusCompanyModel";
import ResRestaurantModel from "./model/res/ResRestaurantModel";
import ResShopModel from "./model/res/ResShopModel";
import ResTravellerModel from "./model/res/ResTravellerModel";
import SystemOrderModel from "./model/account/SystemOrderModel";
import SystemMessageModel from "./model/account/SystemMessageModel";
import RecycleModel from "./model/RecycleModel";
import TradeAgencyModel from "./model/trade/TradeAgencyModel";
import TradeApplicationModel from "./model/trade/TradeApplicationModel";
import TradeAgencyProductModel from "./model/trade/TradeAgencyProductModel";
import ResTravellerExportModel from "./model/res/ResTravellerExportModel";
import FileModel from "./model/FileModel";
import UserModel from "./model/account/UserModel";
import DepartmentModel from "./model/account/DepartmentModel";

//param
import FilterParam from "./param/FilterParam";
import PageParam from "./param/PageParam";
var Constants = {
  Entity: Entity,
  Common: Common,
  ResData: ResData,
  FileData: FileData,
  AgencyProductData: AgencyProductData,
  SalesOrderData: SalesOrderData,
  TravelAgencyData: TravelAgencyData,
  TradeData: TradeData,
  RecycleData: RecycleData,
  SystemVersionData: SystemVersionData,
  SystemOrderData: SystemOrderData,
  SystemMessageData: SystemMessageData,
  AccountData: AccountData,
  MetaData: MetaData,
  AgencyProductModel: AgencyProductModel,
  AgencyScheduleProductModel: AgencyScheduleProductModel,
  SalesOrderModel: SalesOrderModel,
  BuyerOrderModel: BuyerOrderModel,
  OrderOperationModel: OrderOperationModel,
  ResTravelAgencyModel: ResTravelAgencyModel,
  ResHotelModel: ResHotelModel,
  ResLandscapeModel: ResLandscapeModel,
  ResTourGuideModel: ResTourGuideModel,
  ResBusCompanyModel: ResBusCompanyModel,
  ResRestaurantModel: ResRestaurantModel,
  ResShopModel: ResShopModel,
  ResTravellerModel: ResTravellerModel,
  ResTravellerExportModel: ResTravellerExportModel,
  FileModel: FileModel,
  UserModel: UserModel,
  DepartmentModel: DepartmentModel,
  SystemOrderModel: SystemOrderModel,
  SystemMessageModel: SystemMessageModel,
  RecycleModel: RecycleModel,
  TradeAgencyModel: TradeAgencyModel,
  TradeApplicationModel: TradeApplicationModel,
  TradeAgencyProductModel: TradeAgencyProductModel,
  FilterParam: FilterParam,
  PageParam: PageParam
};
var Models = (_Models = {}, _defineProperty(_Models, AgencyProductModel.id, AgencyProductModel), _defineProperty(_Models, AgencyScheduleProductModel.id, AgencyScheduleProductModel), _defineProperty(_Models, SalesOrderModel.id, SalesOrderModel), _defineProperty(_Models, OrderOperationModel.id, OrderOperationModel), _defineProperty(_Models, BuyerOrderModel.id, BuyerOrderModel), _defineProperty(_Models, ResTravelAgencyModel.id, ResTravelAgencyModel), _defineProperty(_Models, ResHotelModel.id, ResHotelModel), _defineProperty(_Models, ResLandscapeModel.id, ResLandscapeModel), _defineProperty(_Models, ResTourGuideModel.id, ResTourGuideModel), _defineProperty(_Models, ResBusCompanyModel.id, ResBusCompanyModel), _defineProperty(_Models, ResRestaurantModel.id, ResRestaurantModel), _defineProperty(_Models, ResShopModel.id, ResShopModel), _defineProperty(_Models, ResTravellerModel.id, ResTravellerModel), _defineProperty(_Models, ResTravellerExportModel.id, ResTravellerExportModel), _defineProperty(_Models, FileModel.id, FileModel), _defineProperty(_Models, UserModel.id, UserModel), _defineProperty(_Models, DepartmentModel.id, DepartmentModel), _defineProperty(_Models, SystemOrderModel.id, SystemOrderModel), _defineProperty(_Models, SystemMessageModel.id, SystemMessageModel), _defineProperty(_Models, RecycleModel.id, RecycleModel), _defineProperty(_Models, TradeAgencyModel.id, TradeAgencyModel), _defineProperty(_Models, TradeApplicationModel.id, TradeApplicationModel), _defineProperty(_Models, TradeAgencyProductModel.id, TradeAgencyProductModel), _Models);
var ResModels = [ResTravelAgencyModel, ResHotelModel, ResLandscapeModel, ResTourGuideModel, ResBusCompanyModel, ResRestaurantModel, ResShopModel, ResTravellerModel];
Constants.install = function (Vue) {
  Vue.prototype.$cdata = function (t, n) {
    t = t + 'Data';
    return Constants[t][n];
  };
  Vue.prototype.$Entity = Constants.Entity;
  Vue.prototype.$Common = Constants.Common;
  Vue.prototype.$ResData = Constants.ResData;
  Vue.prototype.$FileData = Constants.FileData;
  Vue.prototype.$AgencyProductData = Constants.AgencyProductData;
  Vue.prototype.$SalesOrderData = Constants.SalesOrderData;
  Vue.prototype.$OrderOperationModel = Constants.OrderOperationModel;
  Vue.prototype.$TravelAgencyData = Constants.TravelAgencyData;
  Vue.prototype.$TradeData = Constants.TradeData;
  Vue.prototype.$RecycleData = Constants.RecycleData;
  Vue.prototype.$SystemVersionData = Constants.SystemVersionData;
  Vue.prototype.$SystemOrderData = Constants.SystemOrderData;
  Vue.prototype.$SystemMessageData = Constants.SystemMessageData;
  Vue.prototype.$AccountData = Constants.AccountData;
  Vue.prototype.$MetaData = Constants.MetaData;
  Vue.prototype.$AgencyProductModel = Constants.AgencyProductModel;
  Vue.prototype.$AgencyScheduleProductModel = Constants.AgencyScheduleProductModel;
  Vue.prototype.$SalesOrderModel = Constants.SalesOrderModel;
  Vue.prototype.$BuyerOrderModel = Constants.BuyerOrderModel;
  Vue.prototype.$ResTravelAgencyModel = Constants.ResTravelAgencyModel;
  Vue.prototype.$ResHotelModel = Constants.ResHotelModel;
  Vue.prototype.$ResLandscapeModel = Constants.ResLandscapeModel;
  Vue.prototype.$ResTourGuideModel = Constants.ResTourGuideModel;
  Vue.prototype.$ResBusCompanyModel = Constants.ResBusCompanyModel;
  Vue.prototype.$ResRestaurantModel = Constants.ResRestaurantModel;
  Vue.prototype.$ResShopModel = Constants.ResShopModel;
  Vue.prototype.$ResTravellerModel = Constants.ResTravellerModel;
  Vue.prototype.$ResTravellerExportModel = Constants.ResTravellerExportModel;
  Vue.prototype.$FileModel = Constants.FileModel;
  Vue.prototype.$UserModel = Constants.UserModel;
  Vue.prototype.$DepartmentModel = Constants.DepartmentModel;
  Vue.prototype.$SystemOrderModel = Constants.SystemOrderModel;
  Vue.prototype.$SystemMessageModel = Constants.SystemMessageModel;
  Vue.prototype.$RecycleModel = Constants.RecycleModel;
  Vue.prototype.$TradeAgencyModel = Constants.TradeAgencyModel;
  Vue.prototype.$TradeApplicationModel = Constants.TradeApplicationModel;
  Vue.prototype.$TradeAgencyProductModel = Constants.TradeAgencyProductModel;
  Vue.prototype.$Models = Models;
  Vue.prototype.$ResModels = ResModels;
  Vue.prototype.$FilterParam = Constants.FilterParam;
  Vue.prototype.$PageParam = Constants.PageParam;
};
export default Constants;