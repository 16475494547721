var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "em",
    _vm._g(
      { staticClass: "little-tag-type", class: _vm.computedClasses },
      _vm.$listeners
    ),
    [
      _vm.computedIconClass
        ? _c("svg-icon", { attrs: { "icon-class": _vm.computedIconClass } })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.computedLabel) } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }