import _objectSpread from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgIcon from "../SvgIcon/SvgIcon.vue";
export default {
  components: {
    SvgIcon: SvgIcon
  },
  props: {
    image: {
      type: Object
    },
    width: {
      type: [Number, String],
      default: 50
    },
    height: {
      type: Number,
      default: 50
    },
    showWhenEmpty: {
      type: Boolean,
      default: true
    },
    errorIcon: {
      type: String,
      default: 'el-icon-picture'
    },
    errorIconSize: {
      type: Number,
      default: 20
    },
    errorIconColor: {
      type: String,
      default: '#cdcdcd'
    },
    errorDesc: {
      type: String
    },
    errorIconOtherStyles: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {
    imageUrl: function imageUrl() {
      if (this.isEmptyImage()) {
        return '';
      }
      return this.image.url;
    },
    show: function show() {
      return this.showWhenEmpty || !this.isEmptyImage();
    },
    imageStyle: function imageStyle() {
      var style;
      if (typeof this.width === 'number') {
        style = {
          width: this.width + 'px'
        };
        if (this.isEmptyImage()) {
          style.height = this.height + 'px';
        }
      } else {
        style = {
          width: this.width
        };
      }
      return style;
    },
    errorIconStyle: function errorIconStyle() {
      return _objectSpread({
        'font-size': this.errorIconSize + 'px',
        color: this.errorIconColor
      }, this.errorIconOtherStyles);
    }
  },
  methods: {
    isEmptyImage: function isEmptyImage() {
      return this.image === undefined || this.image === null || this.image.url === undefined || this.image.url === null || typeof this.image.url === 'string' && this.image.url.length === 0;
    }
  }
};