import _typeof from "/var/lib/jenkins/workspace/web-front-order/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commonFilterHandler from '@/mixins/commonFilterHandler.js';
export default {
  mixins: [commonFilterHandler],
  props: {
    query: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    resetCallback: {
      type: Function,
      default: function _default() {
        return void 0;
      }
    },
    conditionPanelItemSpan: {
      type: Number,
      default: 6
    }
  },
  computed: {
    computedItems: function computedItems() {
      var returnItems = [];
      for (var i = 0; i < this.items.length; i++) {
        var v = this.items[i];
        if (typeof v == 'string') {
          returnItems.push({
            attr: v
          });
        } else if (_typeof(v) == 'object') {
          returnItems.push(v);
        }
      }
      return returnItems;
    }
  }
};